import React, { createContext, useReducer } from "react";
import { isLoggedIn, getAuth } from './shared/utils';

let signedIn = isLoggedIn();
let firstName = 'Guest';

if (getAuth() !== undefined && getAuth().user !== undefined)
  firstName = getAuth().user.first_name;

const initialState = { signedIn: signedIn, username: firstName };
const store = createContext(initialState);
const { Provider } = store;

interface State {
  signedIn: boolean;
  username: string;
}

const SigninProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "isVerified":
        return { signedIn: false, username: "Guest" };
      case "isNotVerified":
        return { signedIn: false, username: "Guest" };
      case "loggedOut":
        return { signedIn: false, username: "Guest" };
      default:
        return state;
    }
  }, initialState);
  //
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, SigninProvider };
