import React, {useState, useEffect} from "react";
import {makeStyles, Theme, createStyles} from "@material-ui/core/styles";
import {
    Grid,
    Stepper,
    Step,
    StepLabel,
    Fab,
    Button,
    Paper,
    Card,
    CardContent,
    Typography,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, CardActions, Box
} from "@material-ui/core";
import SliderRow from "../components/myValues/SliderRow";
import CButton from "../components/atoms/CButton";
import PieChart from "../components/shared/PieChart";
import axios from "../axios";
import {useParams, useHistory, useLocation} from "react-router-dom";
import Simulation from "../components/shared/Simulation";
import SimulateAnimation from "../components/shared/SimulateAnimation";
import {cleanOutput, getToken, simulationParams} from "../shared/utils";
import {saveToLocal, readFromLocal} from "../shared/utils";
import StepButton from "@material-ui/core/StepButton";
import SimulationByConfig from "../components/shared/SimulationByConfig";
import coin from "../assets/coin.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

interface element {
    name: string;
    display_name: string;
    description: string;
    help_text: string;
    value: number | number[];
}

type handler = (
    event: React.ChangeEvent<{}>,
    newValue?: number | number[]
) => void;
type inputHandler = (event: any) => void;
type switchHandler = (event: any) => void;

interface ElementDetails {
    heading: string;
    description: string;
    component: string;
    componentDescription: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    center: {},
    container: {
        // padding: "0px 16px 20px 16px",
        width: "100%",
        [theme.breakpoints.up("md")]: {
            marginTop: "37px",
        },
    },
    root: {
        maxWidth: "1359px",
        //to connect with global color
        backgroundColor: "#ffffff",
        padding: "20px 15px",
        borderRadius: "10px",
        margin: "0px auto",
        //marginTop: "37px",
    },
    instruction: {
        fontSize: "16px",
        color: "#222222",
        margin: "20px",
        [theme.breakpoints.up("md")]: {
            // display: "none",
        },
    },
    stepLabel: {
        fontSize: "16px",
        color: "#222222",
        margin: "20px",
        fontWeight: "bold",
        [theme.breakpoints.up("md")]: {
            // display: "none",
        },
    },
    buttonsDiv: {
        width: "80%",
        height: "auto",
        //display: "flex",
        //alignItems: "center",
        //justifyContent: "center",
    },
    heading: {
        fontSize: "16px",
        fontWeight: "bold",
        [theme.breakpoints.up("md")]: {
            fontSize: "20px",
        },
    },
    description: {
        fontSize: "12px",
        color: "#767676",
        marginBottom: "30px",
        [theme.breakpoints.up("md")]: {
            fontSize: "16px",
        },
    },
    options: {
        paddingLeft: "20px",
    },
    thirdDiv: {
        backgroundColor: "#ffffff",
        // marginTop: "76px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "10px",
        paddingBottom: "40px",
    },
    fixedButton: {
        position: "fixed",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: "1",
        top: "0",
        margin: "auto",
        [theme.breakpoints.up("md")]: {
            // width: "50vw",
        },


    },
    descRoot: {
        backgroundColor: "#ffffff",
        // margin: "0px 16px",
        border: "1px solid #fafafa",
        borderRadius: "10px",
        padding: "25px 15px",
        [theme.breakpoints.up("md")]: {
            borderWidth: "0px",
            fontSize: "16px",
            color: "#222222",
            padding: "40px 20px",
            margin: "0px 0px",
            borderRadius: "0px",
        },
    },
    descHeading: {
        fontSize: "18px",
        fontWeight: "bold",
        [theme.breakpoints.up("md")]: {
            fontSize: "30px",
        },
    },
    descDescription: {
        fontSize: "14px",
        [theme.breakpoints.up("md")]: {
            fontSize: "16px",
        },
    },
    second: {
        display: "block",
        marginTop: "20px",
        [theme.breakpoints.up("md")]: {
            display: "block",
        },
    },
    cardRoot: {
        minWidth: "50vw",
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(6),
        right: theme.spacing(2),
    },
    button: {
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            textAlign: "end",
        },
    },
}));

const ClientFundPreview = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const id = useParams().id !== undefined ? useParams().id : '';
    const [state, setState] = useState<element[]>([]);
    const [showSimulateAnimation, setShowSimulateAnimation] = useState(false);
    const [floatButtons, setFloatButtons] = useState(false);
    const [item, setItem] = useState({});
    const [type, setType] = useState("");
    const [universe, setUniverse] = useState({});
    const [rebalancingFrequency, setRebalancingFrequency] = useState<string>(
        readFromLocal('rebalancingFrequency') || 'MONTHLY'
    );
    const {stepNumber} = 1;


    const handleStep = (step: any) => () => {
        if (step === 2)
            handleNext()
        else {
            // history.push("/highest-returns/" + (step))
        }
    };
    // useEffect(() => {
    // }, []);


    function handleScroll() {
        if (document.querySelector('#base').scrollTop > 150) {
            setFloatButtons(true)
        } else {
            setFloatButtons(false)
        }
    };

    const handleNext = (event: React.ChangeEvent<{}>): void => {
        let mandate = "Advisor_Fund"
        if (id)
            mandate = "Shared_Fund"
        if (type === "beta")
            handleNextBuildPortfolio(mandate, {
                factors: readFromLocal("_highest_returns_factors"), universe_selection: readFromLocal("universe")
            }, setShowSimulateAnimation, simulationParams[type])
        if (type === "themes")
            handleNextBuildPortfolio(mandate, {
                sectors: readFromLocal("_sectors"), universe_selection: readFromLocal("universe")
            }, setShowSimulateAnimation, simulationParams[type])
        if (type === "themes2")
            handleNextBuildPortfolio(mandate, {
                sub_sector_tilts: readFromLocal("_sub_sector_tilts"), universe_selection: readFromLocal("universe")
            }, setShowSimulateAnimation, simulationParams[type])
    };

    function handleNextBuildPortfolio(
        mandate: string,
        portfolioConfig: any,
        setShowSimulateAnimation: any,
        simulationPath: string,
    ) {

        let universe_exclusions = readFromLocal("universe_exclusions");
        if (universe_exclusions !== undefined) {
            portfolioConfig.universe_selection.universe_exclusions = universe_exclusions;
        }
        saveToLocal('latestRouteMandate', mandate);
        saveToLocal('portfolioConfig-' + mandate, portfolioConfig);
        saveToLocal("lastCall", {data: portfolioConfig, link: simulationPath});
        setShowSimulateAnimation(true);
        axios.post(simulationPath, portfolioConfig)
            .then((res: any) => {
                //save the result to the local storage for the results page
                if (res.status === 200) {
                    let portfolioId = id ? id : location.state.data['id']
                    setShowSimulateAnimation(false);
                    saveToLocal("simulationResult", res.data);
                    saveToLocal("sharedportfolioID", portfolioId);
                    saveToLocal('simulationResult-' + mandate, res.data);
                    if (getToken() === undefined || getToken() === null)
                        location.state && location.state['teamCode'] ? history.push("/register/pvefresult/", {
                                new: 'new', mandate: mandate, team: location.state['teamCode']
                            }) :
                            history.push("/register/pvefresult/", {new: 'new', mandate: mandate})
                    else {
                        if (mandate === "Shared_Fund")
                            history.push("/pvefresult/" + mandate, {
                                new: true,
                                fundID: id,
                                shared: true
                            })
                        else
                            history.push("/pvefresult/" + mandate, {
                                new: true,
                                mode: 'client',
                                advFund: location.state.data['id'],
                                advFundName: location.state.data['name']
                            })
                    }
                } else {
                    alert('Could not continue do to error.');

                }
            });
    };
    useEffect(() => {
        document.querySelector('#base').scrollTo(0, 0)
    }, [])
    useEffect(() => {
        document.querySelector('#base').addEventListener("scroll", handleScroll);
        let portfolioId = location.state ? location.state.data['id'] : id
        axios
            .post("/portfolio/portfolioDetails", {id: portfolioId})
            .then((res) => res.data)
            .then((data) => {
                setItem(data)
            })
            .catch((err) => {
            });
    }, []);
    return (
        <>
            <div className={classes.center}>
                <Grid item container alignItems="center" justify="space-between">
                    <Grid item xs={12}>
                        <Stepper activeStep={1}>
                            {['Performance', 'Portfolio'].map((label, index) => {
                                // const stepProps: { completed?: boolean } = {};
                                // const labelProps: { optional?: React.ReactNode } = {};
                                return (
                                    <Step key={label}>
                                        <StepButton
                                            onClick={handleStep(index + 1)}
                                            // completed={isStepComplete(index)}
                                        >
                                            {/*<StepLabel {...labelProps}>*/}
                                            {label}
                                            {/*</StepLabel>*/}
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" justify="space-around" id="gridButton"
                      style={{paddingTop: "5px"}} className={floatButtons ? classes.fixedButton : ""}>
                    <Button aria-label={"Next"} className="" color="primary" variant="contained"
                            onClick={handleNext}>
                        See Portfolio
                    </Button>
                </Grid>
                <div className={classes.descRoot}>
                    <Grid container>
                        <Grid item xs={12} md={8}>
                            <div className={classes.descHeading}>
                                Welcome to MakeMyFund
                            </div>
                            <div className={classes.second}>
                                <p className={classes.descDescription}>
                                    Build, track and share eFunds - customized investment funds built for direct holding
                                    in stocks.
                                    Invest in them, when you like, with your existing online trading account!
                                </p>
                            </div>
                        </Grid>
                        {/*<Grid item xs={12} md={4} className={classes.button}>*/}
                        {/*    <Button aria-label={"Next"} className="" color="primary" variant="contained"*/}
                        {/*            onClick={handleNext}>*/}
                        {/*        Next*/}
                        {/*    </Button>*/}
                        {/*</Grid>*/}
                    </Grid>
                    {item && item.name &&
                    <Grid container direction="column" spacing={3} alignItems="center" style={{marginTop: "10px"}}>
                        <Grid item xs={12} style={{width: "80%"}}>
                            <Paper elevation={3}>
                                <Card className={classes.cardRoot}>
                                    <CardContent>
                                        <Grid container direction={"row"} justify={"space-between"}>
                                            <Grid item xs={12} md={12}>
                                                <Typography gutterBottom variant="h5"
                                                            component="h3">
                                                    {cleanOutput(item.name)}
                                                </Typography>
                                                {item.details && <Typography variant="body1">
                                                    {item.details['description']}
                                                </Typography>}
                                                {item.universe && <Typography variant="subtitle2">
                                                    Universe: {cleanOutput(item.universe['market_cap'])}
                                                </Typography>}
                                                <Typography variant="subtitle2">
                                                    Portfolio
                                                    Size:{''}{parseFloat(item['stockCount']).toFixed(0)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    {item.userDetails && <CardActions>
                                        <Grid container direction="row"
                                              style={{justifyContent: "flex-end"}}>
                                            {/*        <Grid item>*/}
                                            {/*            <Button size="small" color="primary"*/}
                                            {/*                    onClick={() => (openPreview(item))}>*/}
                                            {/*                Learn More*/}
                                            {/*            </Button>*/}
                                            {/*        </Grid>*/}
                                            <Grid item>
                                                <Typography variant="overline"
                                                            color="textSecondary">
                                                    Created
                                                    By: {item.userDetails['first_name']}{' '}{item.userDetails['last_name']}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardActions>}
                                </Card>
                            </Paper>
                        </Grid>
                    </Grid>}
                    <Grid item container alignItems="center" justify="space-around" id="gridButton"
                          style={{paddingTop: "5px"}} className={floatButtons ? classes.fixedButton : ""}>
                    </Grid>
                </div>
                <SimulationByConfig configId={location.state ? location.state.data['id'] : id}
                                    setType={setType}
                    // link={"/simulation/theme-sectors"}
                                    setUniverse={setUniverse} stepMode={true}
                                    stepNumber={3} preLoad/>
                <SimulateAnimation show={showSimulateAnimation} text="Building your portfolio..."/>
            </div>
        </>
    );
};
export default ClientFundPreview;
