import React, { FunctionComponent, useState } from "react";
import { Grid, Link, Avatar } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import companyLogo from "../assets/companyLogo.png";
import BlogPost from "../pages/BlogPost";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#222222",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
  },
  row: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  first: {
    color: "rgba(100, 100, 100, 80)",
  },
  second: {
    color: "#ffffff",
  },
  third: {
    color: "rgba(100, 100, 100, 83)",
  },
  linkSection: {
    marginTop: "10px",
  },
  link: {
    textDecoration: "none",
    color: "rgba(100, 100, 100, 83)",
    marginLeft: "5px",
    marginRight: "5px",
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <img src={companyLogo} alt="asqi" height={30} />
        &nbsp;&nbsp;
        {/* <span className={classes.first}>&#169;{new Date().getFullYear()}</span> */}
        {/* <span className={classes.second}>&nbsp;ASQI Investment Managers</span> */}
        <span className={classes.second}>
          ASQI Investment Managers Private Limited. CIN: U74900MH2016FTC274805
        </span>
      </div>
      <div className={classes.linkSection}>
        <a
          className={classes.link}
          href="https://s3.ap-south-1.amazonaws.com/makemyfund.in/disclaimer.html"
          target="_blank"
        >
          Disclaimer
        </a>
        <a
          className={classes.link}
          href="https://s3.ap-south-1.amazonaws.com/makemyfund.in/termsofuse.html"
          target="_blank"
        >
          Terms of use
        </a>
        <a
          className={classes.link}
          href="https://s3.ap-south-1.amazonaws.com/makemyfund.in/privacy.html"
          target="_blank"
        >
          Privacy policy
        </a>
        <a
          className={classes.link}
          href="https://s3.ap-south-1.amazonaws.com/makemyfund.in/Asqi+Audit+2021-22.pdf"
          target="_blank"
        >
          Annual Return FY21-22 (MGT-7)
        </a>
        <a
          className={classes.link}
          href="https://s3.ap-south-1.amazonaws.com/makemyfund.in/AnnexureA"
          target="_blank"
        >
          Investor Charter
        </a>
        <a
          className={classes.link}
          href="https://s3.ap-south-1.amazonaws.com/makemyfund.in/Website/Complaint_Board/Disposal+of+Complaints.pdf"
          target="_blank"
        >
          Complaint Board
        </a>
        <a
          className={classes.link}
          href="https://s3.ap-south-1.amazonaws.com/makemyfund.in/AnnexureC"
          target="_blank"
        >
          Grievance Redressal
        </a>
        <a
          className={classes.link}
          href="https://s3.ap-south-1.amazonaws.com/makemyfund.in/Annual+Compliance+Audit.pdf"
          target="_blank"
        >
          Annual Compliance Audit Report
        </a>
        <a className={classes.link} href="/blog" target="_blank">
          Blog
        </a>
        {/* <Link to="/BlogPost/">Blog</Link> */}
      </div>
    </div>
  );
};
//d>{`&#169; ${new Date().getFullYear()}`}</span>
export default Footer;
