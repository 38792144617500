import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

const ColumnChart: React.FC<{ chartName: string; data: any }> = (props) => {
  const { chartName, data } = props;

  const chartOptions = {
    chart: {
        type: 'column'
    },
    title: {
        text: 'YoY Returns'
    },
    credits: {
        enabled: false,
    },
    subtitle: {
        text: 'Portfolio vs Benchmark'
    },
    xAxis: {
        categories: data['year'],
        crosshair: true
    },
    yAxis: {
        title: {
            text: 'Returns (%)'
        }
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [{
        name: 'Portfolio',
        data: data['portfolio']

    }, {
        name: 'Benchmark',
        data: data['index']

    }]
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default ColumnChart;
