import React, {
  FunctionComponent,
  useState,
  useContext,
  useEffect,
} from "react";
import { Grid, Link, Avatar, Menu, MenuItem, Box } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import companyLogo from "../assets/companyLogo.png";
import { useParams, useRouteMatch } from "react-router-dom";
import menu from "../assets/menu.svg";
import { store } from "../SignIn";
import {
  logout,
  getAuth,
  checkAdvisor,
  readFromLocal,
  unsecuredPages,
  checkRole,
  checkCredenceClient,
} from "../shared/utils";
import MakeMyFundLogo from "../assets/makemyfund_2.png";
import AdvisoryLogo from "../assets/nriLogo.jpg";
import instance from "../axios";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useLocation } from "react-router-dom";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ClassObject {
  [index: string]: string;
}

interface Props {
  toggleDrawer: any;
  sideBarOpen: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  logoDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "23px 20px 0px 26px",
  },
  loggedIn: {},
  root: {
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#ffffff",
    },
  },
  linkDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  linkWrapper: {
    position: "relative",
    marginRight: "30px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  link: {
    fontWeight: "normal",
    color: "#767676",
    // "&:after": {
    //   width: "6px",
    //   height: "6px",
    //   borderRadius: "100%",
    //   backgroundColor: "#0b54f6",
    //   content: '"h"',
    //   position: "absolute",
    //   color: "rgba(0,0,0,0)",
    //   top: "120%",
    //   left: "50%",
    // },
  },
  bottomLine: {
    margin: "20px 20px 0px 20px",
    [theme.breakpoints.up("md")]: {
      borderBottom: "2px solid #e0e0e0",
    },
  },
  avatar: {
    marginLeft: "17px",
  },
  menu: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  button: {
    backgroundColor: "#e5edff",
    color: "#0b54f6",
    borderRadius: "10px",
    //boxShadow: "0px 20px 30px #cddbfd",
    textDecoration: "none",
    height: "46px",
    fontWeight: 600,
    padding: "0px 30px",
    display: "inline-block",
    lineHeight: "46px",
  },
  headerButton: {
    backgroundColor: "#e5edff",
    color: "#0b54f6",
    borderRadius: "10px",

    textDecoration: "none",
    height: "46px",
    fontWeight: 600,
    padding: "0px 30px",
    display: "inline-block",
    lineHeight: "46px",
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      width: "135px",
      fontSize: "17px",
      textAlign: "center",
      marginLeft: "55px",
      padding: "1px",
    },
    // [theme.breakpoints.down("md")]: {
    //   width: "150px",
    //   fontSize: "11px",

    //   textAlign: "center",
    // },
  },
  buttonBox: {
    display: "flex",
    justifyContent: "space-around",
  },
  gridWidth: {
    maxWidth: "50%",
  },
}));
const isAdvisory = checkRole("Advisor");
const isClient = checkRole("Client");
const isAdmin = checkRole("Admin");
const team = readFromLocal("teamData");
const isCredenceClient = checkCredenceClient()

const LogoDiv = (props) => {
  const { classes } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  function homepage() {
    if (isClient) return "/";
    return props.team ? "/" + props.team.code : "/";
  }

  return (
    <>
      {/*<div style={{display: "table", borderCollapse: "separate", borderSpacing: "10px"}}>*/}
      <Grid
        container
        direction={isMobile ? "column" : "row"}
        justify="flex-start"
        alignItems="center"
        spacing={1}
        className={classes.gridWidth}
      >
        {/*<div style={{display: "table-row"}}>*/}
        <Grid item>
          <a href={isCredenceClient ? '/dashboard' : homepage()}>
            <img src={MakeMyFundLogo} alt="makemyfund" height={36} />
          </a>
        </Grid>
        {team && team.logoUrl && (
          <Grid item>
            <a href={homepage()}>
              <img src={team.logoUrl} alt="NRI" height={36} />
            </a>
          </Grid>
        )}
        {props.team && props.team.logoUrl && (
          <Grid item>
            <a href={homepage()}>
              <img src={props.team.logoUrl} alt="NRI" height={36} />
            </a>
          </Grid>
        )}
        {/*</div>*/}
        {/*</div>*/}
      </Grid>
      {/* <img src={companyLogo} alt="company" />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <a style={{ textDecoration: "none" }} href="/">
          <h2 style={{ color: "blue" }}>
            MakeMyFund{" "}
            <span style={{ color: "teal", fontSize: "0.65em" }}>
              alpha v0.1
            </span>
          </h2>
        </a>
      </div>
	*/}
    </>
  );
};
const LinkDiv = (props) => {
  const { classes } = props;
  const globalStore = useContext(store);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isAdvisor, setIsAdvisor] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const first_name = globalStore.state.username;
  if(isCredenceClient){
    return (
      <div className={classes.linkDiv}>
        <div
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <Avatar
            className={classes.avatar}
            src="../assets/profile.jpeg"
            alt={first_name}
          />
        </div>
        <Menu
          id="user-context-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
      </div>
    );
  }

  return (
    <div className={classes.linkDiv}>
      {(isAdvisory || isAdmin) && (
        <>
          <div className={classes.linkWrapper}>
            <Link className={classes.link} href="/advisoryFund">
              Advisory Funds
            </Link>
          </div>
          <div className={classes.linkWrapper}>
            <Link className={classes.link} href="/clients">
              Clients
            </Link>
          </div>
        </>
      )}
      <div className={classes.linkWrapper}>
        <Link className={classes.link} href="/invest">
          Invest
        </Link>
      </div>
      {isClient && (
        <>
          <div className={classes.linkWrapper}>
            <Link className={classes.link} href="/myFunds">
              My Funds
            </Link>
          </div>
          <div className={classes.linkWrapper}>
            <Link className={classes.link} href="/dashboard">
              My Dashboard
            </Link>
          </div>
          <div className={classes.linkWrapper}>
            <Link className={classes.link} href="/account">
              Account
            </Link>
          </div>
        </>
      )}
      {!isClient && (
        <>
          <div className={classes.linkWrapper}>
            <Link className={classes.link} href="/publicFunds">
              Browse ready eFunds
            </Link>
          </div>
        </>
      )}
      <div
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <Avatar
          className={classes.avatar}
          src="../assets/profile.jpeg"
          alt={first_name}
        />
      </div>
      <Menu
        id="user-context-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => window.open("/dashboard", "_self")}>
          My Dashboard
        </MenuItem>
        <MenuItem onClick={() => window.open("/account", "_self")}>
          Account
        </MenuItem>
        {/*<MenuItem onClick={() => window.open("/CryptoFunds", "_self")}>Crypto Funds</MenuItem>*/}
        {isAdmin && (
          <>
            <MenuItem onClick={() => window.open("/team-details", "_self")}>
              Team Details
            </MenuItem>
            <MenuItem onClick={() => window.open("/client-portfolio", "_self")}>
              Client Portfolios
            </MenuItem>
          </>
        )}
        {(isAdvisory || isAdmin) && (
          <MenuItem onClick={() => window.open("/dashboard/custom-portfolio", "_self")}>
            Custom Portfolio
          </MenuItem>
        )}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};
const MenuDiv = (props) => {
  const { classes, toggleDrawer, sideBarOpen } = props;
  return (
    <div
      className={classes.menu}
      onClick={(event: any) => {
        toggleDrawer(true)();
        event.preventDefault();
      }}
    >
      <img src={menu} alt="menu" id="mobilemenu" />
    </div>
  );
};
const Header = (props: Props) => {
  const classes = useStyles();
  const globalStore = useContext(store);
  const { toggleDrawer, sideBarOpen } = props;
  const signedIn = globalStore.state.signedIn;
  const [teamInfo, setTeamInfo] = useState<State>(undefined);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const location = useLocation();
  const [slug, setSlug] = useState<String>(location.pathname);

  function filterSplit(split: string) {
    if (split !== "" && !unsecuredPages.includes(split)) return true;
    else return false;
  }

  useEffect(() => {
    if (!signedIn) {
      let split = slug.split("/");
      if (split && filterSplit(split[split.length - 1])) {
        instance
          .post("/account/getTeamUnsecured", { slug: split[split.length - 1] })
          .then((res) => {
            if (res.isAxiosError) {
              setSlug("");
              return;
            }
            setTeamInfo(res.data);
            setSlug("/" + split[split.length - 1]);
          })
          .catch((err) => { });
      } else if (readFromLocal("originLink")) {
        instance
          .post("/account/getTeamUnsecured", {
            slug: readFromLocal("originLink"),
          })
          .then((res) => {
            if (res.isAxiosError) {
              setSlug("");
              return;
            }
            setTeamInfo(res.data);
            // setSlug("/" + split[split.length - 1])
          })
          .catch((err) => { });
      } else {
        setSlug("");
      }
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.logoDiv}>
        {signedIn ? (
          <>
            <MenuDiv
              classes={classes}
              sidebarOpen={sideBarOpen}
              toggleDrawer={toggleDrawer}
            />
            <LogoDiv classes={classes} />
            <LinkDiv classes={classes} />
          </>
        ) : (
          <>
            <LogoDiv classes={classes} team={teamInfo} />
            {/*<div className={classes.buttonBox}>*/}
            <Grid
              container
              className={classes.root}
              spacing={2}
              justify="flex-end"
            >
              {
                <Grid item>
                  <a
                    href={"/login"}
                    className={classes.headerButton}
                  // zstyle={{ fontSize: "16px" }}
                  >
                    Login
                  </a>
                </Grid>
              }
              <Grid item>
                <a
                  href={"/onboard-partner"}
                  className={classes.headerButton}
                  style={{ marginLeft: "1rem" }}
                >
                  Partner With Us
                </a>
              </Grid>
            </Grid>
            {/*</div>*/}
          </>
        )}
      </div>
      <div className={classes.bottomLine} />
    </div>
  );
};
export default Header;
