import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { Table, TableBody, TableHead, TableCell, TableRow, TableContainer, Paper } from "@material-ui/core";

const PerformanceTable: React.FC<{ data: any }> = (props) => {
    const { data } = props;

    return (
        <div>
            {/* {`Returns pa: ${data.returns_pa}`}%
            {`Returns 1y: ${data.returns_pa_1y}`}%
            {`Returns 5y: ${data.returns_pa_5y}`}%
            {`Benchmark Returns 1y: ${data.benchmark_returns_pa_1y}`}%
            {`Benchmark Returns 5y: ${data.benchmark_returns_pa_5y}`}%
            {`Benchmark Returns pa: ${data.benchmark_returns_pa}`}%
            {`Outperformance pa : ${data.outperformance_pa}`}%
            {`Transaction Cost : ${data.total_transaction_cost}`}% */}
            {/* <Table>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Returns pa
                        </TableCell>
                        <TableCell align="right">{data.returns_pa}%</TableCell>
                        <TableCell component="th" scope="row">
                            Benchmark Returns pa
                        </TableCell>
                        <TableCell align="right">{data.benchmark_returns_pa}%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Returns 1y
                        </TableCell>
                        <TableCell align="right">{data.returns_pa_1y}%</TableCell>
                        <TableCell component="th" scope="row">
                            Benchmark Returns 1y
                        </TableCell>
                        <TableCell align="right">{data.benchmark_returns_pa_1y}%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Returns 5y
                        </TableCell>
                        <TableCell align="right">{data.returns_pa_5y}%</TableCell>
                        <TableCell component="th" scope="row">
                            Benchmark Returns 5y
                        </TableCell>
                        <TableCell align="right">{data.benchmark_returns_pa_5y}%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Outperformance pa
                        </TableCell>
                        <TableCell align="right">{data.outperformance_pa}%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Transaction Cost
                        </TableCell>
                        <TableCell align="right">{data.total_transaction_cost}%</TableCell>
                    </TableRow>

                </TableBody>
            </Table> */}
            <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Period</TableCell>
                        <TableCell>eFund</TableCell>
                        <TableCell>Benchmark</TableCell>
                    </TableRow>
                </TableHead>
                
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Returns p.a. (2007-now)
                        </TableCell>
                        <TableCell>{data.returns_pa}%</TableCell>
                        <TableCell>{data.benchmark_returns_pa}%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Returns 1y
                        </TableCell>
                        <TableCell>{data.returns_pa_1y}%</TableCell>
                        <TableCell>{data.benchmark_returns_pa_1y}%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Returns 5y
                        </TableCell>
                        <TableCell>{data.returns_pa_5y}%</TableCell>
                        <TableCell>{data.benchmark_returns_pa_5y}%</TableCell>
                    </TableRow>
                    {/* <TableRow>
                        <TableCell component="th" scope="row">
                            Returns 1y
                        </TableCell>
                        <TableCell align="right">{data.returns_pa_1y}%</TableCell>
                        <TableCell component="th" scope="row">
                            Benchmark Returns 1y
                        </TableCell>
                        <TableCell align="right">{data.benchmark_returns_pa_1y}%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Returns 5y
                        </TableCell>
                        <TableCell align="right">{data.returns_pa_5y}%</TableCell>
                        <TableCell component="th" scope="row">
                            Benchmark Returns 5y
                        </TableCell>
                        <TableCell align="right">{data.benchmark_returns_pa_5y}%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Outperformance pa
                        </TableCell>
                        <TableCell align="right">{data.outperformance_pa}%</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Transaction Cost
                        </TableCell>
                        <TableCell align="right">{data.total_transaction_cost}%</TableCell>
                    </TableRow> */}

                </TableBody>
            </Table>
            </TableContainer>
        </div>
    )
};

export default PerformanceTable;
