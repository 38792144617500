import React, { FunctionComponent, useState } from "react";
import { Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

interface Props {
  value: number;
  handleValue: (
    event: React.ChangeEvent<{}>,
    newValue: number | number[]
  ) => void;
  max?: number;
  min?: number;
  step?: number;
}

const CustomSlider = withStyles({
  root: {
    padding: "13px 0",
    marginBottom: "0px !important",
  },
  thumb: {
    height: 18,
    width: 15,
    backgroundColor: "rgba(0,0,0,0) !important",
    marginTop: -18,
    marginLeft: -7,
    boxShadow: "#ebebeb 0 0px 0px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 0px 0px 0px",
    },
    "& path": {
      fill: "#0b54f6",
    },
  },
  active: {},
  track: {
    height: 6,
    borderRadius: 10,
    backgroundColor: "#0b54f6",
  },
  rail: {
    color: "#d8d8d8",
    opacity: 1,
    height: 6,
    borderRadius: 10,
    backgroundColor: "#efefef",
  },
  mark: {
    display: "none",
  },
  markLabel: {
    display: "none",
  },
})(Slider);

function SVGThumbComponent(props: any) {
  return (
    <span {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.333"
        height="18.15"
        viewBox="0 0 15.333 18.15"
      >
        <path
          className="path"
          d="M7.667,18.15a.988.988,0,0,1-.869-.5l-1.543-2.7a7.667,7.667,0,1,1,4.821,0l-1.543,2.7A.987.987,0,0,1,7.667,18.15Zm0-14.317A3.834,3.834,0,1,0,11.5,7.666,3.838,3.838,0,0,0,7.667,3.833Z"
          transform="translate(0 0)"
        />
      </svg>
    </span>
  );
}
//const SliderP: React.FC<{}> = (props:Props) => {
const SliderP = (props: Props) => {
  return (
    <CustomSlider
      ThumbComponent={SVGThumbComponent}
      value={props.value}
      onChange={props.handleValue}
      max={props.max ? props.max : 100}
      min={props.min ? props.min : 0}
      step={props.step ? props.step : 5}
    />
  );
};
const marks = [
  {
    value: 0,
    label: "None",
  },
  {
    value: 10,
    label: "low",
  },
  {
    value: 50,
    label: "medium",
  },
  {
    value: 100,
    label: "high",
  },
];
export const RestrictedSlider = (props: Props) => {
  return (
    <CustomSlider
      ThumbComponent={SVGThumbComponent}
      value={props.value}
      onChange={props.handleValue}
      max={props.max ? props.max : 100}
      min={props.min ? props.min : 0}
      step={null}
      marks={marks}
      valueLabelDisplay="off"
    />
  );
};
export default SliderP;
