import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FormControl, Select } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      borderRadius: "10px",
      border: "1px solid black",
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    form: {
      //width: 300,
      width: "100%",
      height: 42,
      borderRadius: 10,
    },
    select: {
      width: "100%",
      height: 42,
      padding: "0px !important",
      "& :focus": {
        backgroundColor: "rgba(0,0,0,0)",
      },
    },
    inputLabel: {
      top: -6,
    },
  });
});
type handler = (event: any) => void;
interface Options {
  name: string;
  value: string;
}
interface Props {
  options: Options[];
  handleValue: handler;
  value: string;
}
//const CSelect: React.FC<{options:Options[],handleValue:handler,value:string}> = () => {
const CSelect = (props: Props) => {
  const classes: any = useStyles();
  //const [state, setState] = React.useState<string>(props.value);

  //const handleChange = (event: any) => {
  //  const v = event.target.value;
  //  
  //  setState(v);
  //};

  return (
    <>
      <FormControl className={classes.form} variant="outlined">
        <Select
          native
          className={classes.select}
          value={props.value}
          onChange={props.handleValue}
          inputProps={{
            name: "",
            id: "selection",
          }}
        >
          {props.options.map((e) => (
            <option key={e.value} value={e.value}>
              {e.name}
            </option>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
export default CSelect;
