import { makeStyles, Theme } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Card, CardContent, CardMedia } from "@material-ui/core";
import { Link } from "react-router-dom";
import { blogList } from "../../data";

const useStyles = makeStyles((theme: Theme) => ({
	mainView: {
		borderRadius: "24px",
		backgroundColor: "#fff",
		display: "flex",
		alignItems: "center",
		marginBottom: 50,
		justifyContent: "center",
		[theme.breakpoints.up("md")]: {
			padding: "65px 70px",
		},
	},
	header: {
		fontFamily: "Montserrat",
	},
	blogContainer: {
		paddingTop: theme.spacing(3),
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		[theme.breakpoints.up("md")]: {},
	},
	artical: {
		paddingBottom: theme.spacing(3),
		textAlign: "center",
		fontSize: 30,
		fontWeight: 700,
	},
	card: {
		maxWidth: "70%",
		height: "98%",
		marginBottom: 30,
		marginTop: 30,
		[theme.breakpoints.down("md")]: {
			maxWidth: "90%",
		},
	},
}));

const BlogItem = ({ blog }) => {
	const classes = useStyles();
	return (
		<div className={classes.mainView}>
			<Container maxWidth='lg' className={classes.blogContainer}>
				<Grid container style={{ marginTop: 20, marginBottom: 100 }}>
					{blogList.map((post) => (
						<Grid
							item
							sm={6}
							md={4}
							className='row'
							style={{ marginTop: "auto" }}>
							<Card className={classes.card}>
								<Link
									to={`/blog/${post.slug}`}
									style={{ textDecoration: "unset", color: "unset" }}>
									<CardMedia
										component='img'
										height='140'
										image={post.authorAvatar}
										alt='green iguana'
										className={classes.media}
									/>
									<CardContent>
										<Typography
											gutterBottom
											variant='h5'
											component='div'
											style={{
												position: "relative",
												maxHeight: "36px",
												overflow: "hidden",
												fontWeight: 700,
												paddingLeft: "0.2rem",
											}}>
											{post.title}
										</Typography>

										<Typography style={{ marginLeft: "0.6rem" }}>
											{post.id === 1
												? "Reading time: 5mins"
												: "Reading time: 3mins"}
										</Typography>
										<Typography
											variant='body2'
											style={{
												position: "relative",
												maxHeight: "60px",
												overflow: "hidden",
												fontSize: "15px",
												paddingLeft: "0.6rem",
												marginTop: 15,
											}}>
											{post.description}
										</Typography>
									</CardContent>
								</Link>
							</Card>
						</Grid>
					))}
				</Grid>
			</Container>
		</div>
	);
};

export default BlogItem;
