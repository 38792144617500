import React, {useState, useEffect} from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Button, Grid} from "@material-ui/core";
import instance from "../axios";
import TimeAreaGraph from "../shared/TimeAreaGraph.tsx";
import ColumnChart from "../shared/ColumnChart.tsx";
import {CSVLink} from "react-csv";
import CachedIcon from "@material-ui/icons/Cached";
import {Tab, Tabs, Box, Typography} from "@material-ui/core";
import PerformanceTable from "./PerformanceTable";

interface element {
    name: string;
    value: number | number[];
}

type handler = (
    event: React.ChangeEvent<{}>,
    newValue?: number | number[]
) => void;
type inputHandler = (event: any) => void;
type switchHandler = (event: any) => void;

interface ElementDetails {
    heading: string;
    description: string;
    component: string;
    componentDescription: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        //padding: "0px 16px 20px 16px",
    },
    center: {
        //margin: "0px auto",
        //marginTop: "37px",
    },
    root: {
        maxWidth: "1359px",
        //to connect with global color
        backgroundColor: "#ffffff",
        // padding: "20px 15px",
        borderRadius: "10px",
        // margin: "0px auto",
        // marginTop: "37px",
    },
    buttonsDiv: {
        width: "100%",
        height: "auto",
        //display: "flex",
        //alignItems: "center",
        //justifyContent: "center",
    },
    heading: {
        fontSize: "20px",
        fontWeight: "600px",
    },
    description: {
        fontSize: "16px",
        color: "#767676",
        marginBottom: "30px",
    },
    options: {
        paddingLeft: "20px",
    },
    thirdDiv: {
        backgroundColor: "#ffffff",
        marginTop: "76px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "50px 0px",
    },
    simulateDiv: {
        margin: "0px auto",
        // marginTop: "37px",
        // padding: "30px 20px 36px 25px",
        maxWidth: "1359px",
        backgroundColor: "#fff",
        borderRadius: "10px",
        [theme.breakpoints.up("md")]: {
            backgroundColor: "#fafafa",
        },
    },
    simulateHeading: {
        fontSize: "16px",
        fontWeight: 600,
        [theme.breakpoints.up("md")]: {
            fontSize: "20px",
        },
    },
    simulateHeadingLess: {
        fontSize: "14px",
        fontWeight: 600,
        [theme.breakpoints.up("md")]: {
            fontSize: "18px",
        },
    },
    number:{
        fontWeight:"bold"
    },
    numberSpan:{
        [theme.breakpoints.down("md")]: {
            float: "right"
        }
    },
    simulateDescription: {
        fontSize: "12px",
        fontWeight: "normal",
        color: "#767676",
        marginTop: "14px",
        [theme.breakpoints.up("md")]: {
            fontSize: "16px",
        },
    },
    simulateDetails: {
        fontSize: "12px",
        fontWeight: 600,
        [theme.breakpoints.up("md")]: {
            fontSize: "16px",
        },
    },
    simulateLabel: {
        //fontSize: "14px",
        //fontWeight: 600,
        marginLeft: "10px",
        textTransform: "none",
    },
    simulateButton: {
        backgroundColor: "#0b54f6",
        color: "#ffffff",
        fontSize: "14px",
        [theme.breakpoints.up("md")]: {
            backgroundColor: "#0b54f6",
            color: "#ffffff",
            borderRadius: "10px",
            boxShadow: "0px 20px 30px #cddbfd",
            textDecoration: "none",
            fontSize: "18px",
            fontWeight: 600,
            padding: "0px 24px",
            height: "54px",
            lineHeight: "54px",
            width: "150px",
            minWidth: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        "&:hover": {
            backgroundColor: "#0b54f6",
            color: "#ffffff",
        },
        "&:active": {
            backgroundColor: "#0b54f6",
            color: "#ffffff",
        },
    },
    refreshImage: {
        "& path": {
            color: "#0b54f6",
            [theme.breakpoints.up("md")]: {
                color: "#ffffff",
            },
        },
    },
    spacer: {
        height: "20px",
    },
    stepLabel: {
        fontSize: "16px",
        color: "#222222",
        margin: "20px",
        fontWeight: "bold",
        [theme.breakpoints.up("md")]: {
            // display: "none",
        },
    },
}));

interface Performance {
    returns_pa: number | string;
    benchmark_returns_pa: number | string;
    outperformance_pa: number | string;
    total_transaction_cost: number | string;
}

type Data = [number, number][];
type clickHandler = (a: void) => void;

interface Props {
    performance: Performance;
    handleClick: clickHandler;
    data: Data;
    csvData: Array<any>;
    yoyPerformance: Array<any>;
    showStep: string
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const SimulateGraph = (props: Props) => {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);
    const {
        returns_pa,
        benchmark_returns_pa,
        outperformance_pa,
        total_transaction_cost,
    } = props.performance;
    const [eReturns, setEReturns] = useState(returns_pa);
    const [bReturns, setBReturns] = useState(benchmark_returns_pa);
    const [startingDate, setStartingDate] = useState(null);
    const [endingDate, setEndingDate] = useState(null);
    const performance = props.performance;
    const {data, handleClick, csvData, yoyPerformance} = props;
    //  const handleClick = (event: React.ChangeEvent<{}>): void => {
    //
    //    window.open("/myvalues/allocations", "_self");
    //  };
    function setValues(val1, val2, val3, val4) {
        setEReturns(val1)
        setBReturns(val2)
        setStartingDate(val3)
        setEndingDate(val4)
    }

    useEffect(() => {
        if (returns_pa) {
            setEReturns(returns_pa);
            setStartingDate("2007")
            setEndingDate("Now")
        }
    }, [returns_pa]);

    useEffect(() => {
        if (benchmark_returns_pa) {
            setBReturns(benchmark_returns_pa);
        }
    }, [benchmark_returns_pa]);

    return (
        <div className={classes.container}>
            <div id="simulationGraph" className={classes.simulateDiv}>
                <Grid container>
                    <Grid item container style={{padding: '20px'}}>
                        <Grid item xs={12} md={10}>
                            <div className={classes.simulateHeading}>Historical Performance of this eFund</div>
                        </Grid>
                        {/* <Grid item xs={4} md={2}>
                <Button className={classes.simulateButton} onClick={handleClick}>
                  <CachedIcon />
                  <span className={classes.simulateLabel}>Simulate</span>
                </Button>
              </Grid> */}
                        <Grid item xs={12}>
                            <div className={classes.simulateDescription}>
                                {/* Following graph shows the performance of your Personalized Virtual Equity Fund from 2007 till date.
                  <br />
                  The summary numbers are as below. You can check the performance
                  for different periods by moving the slider below the graph. */}
                            </div>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={9} md={10} style={{ marginBottom: "27px" }}>
            <div className={classes.simulateHeading}>Performance</div>
            <div className={classes.simulateDescription}>
              Following graph shows the performance of your Personalized Virtual Equity Fund from 2007 till date.
              <br />
              The summary numbers are as below. You can check the performance
              for different periods by moving the slider below the graph.
            </div>
          </Grid>
          <Grid item xs={3} md={2}>
            <Button className={classes.simulateButton} onClick={handleClick}>
              <CachedIcon />
              <span className={classes.simulateLabel}>Simulate</span>
            </Button>
          </Grid> */}


                    <Tabs value={tabValue} onChange={(event, newValue) => setTabValue(newValue)}>
                        <Tab label="Cumulative Returns"/>
                        {eReturns !== "" && <Tab label="YoY Returns"/>}
                        {eReturns !== "" && <Tab label="Performance Metrics"/>}
                    </Tabs>


                </Grid>

                <TabPanel value={tabValue} index={0}>
                    {eReturns !== "" && <Grid container>
                        <Grid item xs={12} md={6} classes={classes.simulateHeadingLess}>
                            {`eFund Returns:`} <span className={classes.numberSpan}><span className={classes.number} style={{color:"#027ae6"}}>{parseFloat(eReturns).toFixed(1)}</span>{`% per annum`}</span>
                        </Grid>
                        <Grid item xs={12} md={6} classes={classes.simulateHeadingLess}>
                            {`Benchmark returns (${startingDate}-${endingDate}):`} <span className={classes.numberSpan}><span className={classes.number}style={{color:"#e65502"}}> {parseFloat(bReturns).toFixed(1)}</span>{`% per annum`}</span>
                        </Grid>
                    </Grid>}
                    <TimeAreaGraph chartName="returns" data={data} valueSetter={setValues}/>
                    {/* <CSVLink data={csvData} filename="MakeMyFund returns history.csv">Download CSV</CSVLink> */}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <ColumnChart data={yoyPerformance}/>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    {/* <Grid item xs={12} md={6} className={classes.simulateDetails}>
              {`Returns pa : ${returns_pa}`}%
            </Grid>
            <Grid item xs={12} md={6} className={classes.simulateDetails}>
              {`Benchmarks pa : ${benchmark_returns_pa}`}%
            </Grid>
            <Grid item xs={12} md={6} className={classes.simulateDetails}>
              {`Outperformance pa : ${outperformance_pa}`}%
            </Grid>
            <Grid item xs={12} md={6} className={classes.simulateDetails}>
              {`Total Transaction cost pa : ${total_transaction_cost}`}%
            </Grid> */}
                    <PerformanceTable data={performance}/>
                </TabPanel>

            </div>
        </div>
    );
};
export default SimulateGraph;
