//Global constants in this file

// export const HOME_PAGE = process.env.PUBLIC_URL;
export const HOME_PAGE = '/';

export const ASQI_COLOR = "#0e4476";
//Dev
// var _SERVER_BASE_ADDRESS = 'http://localhost:8000';
var _SERVER_BASE_ADDRESS = 'http://172.16.30.204:8000';
// _SERVER_BASE_ADDRESS = 'https://asqisys.com/backend';
var _GOOGLE_OAUTH_CLIENT_ID = '978566420462-jsrnp54ovfn94q05551b3ge73q413h6m.apps.googleusercontent.com'
var _RZP_KEY = 'rzp_test_lIPFFCrCh8ljm1'
var s3FolderPath = 'MMF_KYC/'

if(process.env.NODE_ENV === 'production'){
    // Prod
    // _SERVER_BASE_ADDRESS = '/backend';
    _SERVER_BASE_ADDRESS = 'http://172.16.30.204:8000';
    // _SERVER_BASE_ADDRESS = '/staging-backend';
    _GOOGLE_OAUTH_CLIENT_ID = '978566420462-mk1qca9i61nmgvc30i299arrtqfc3q8g.apps.googleusercontent.com'
    _RZP_KEY = 'rzp_live_SvgK1UWUY0ad5q'
    s3FolderPath='UserDocuments/'
}

// _SERVER_BASE_ADDRESS = 'https://asqisys.com/retail-backend';

export const SERVER_BASE_ADDRESS = _SERVER_BASE_ADDRESS;
export const GOOGLE_OAUTH_CLIENT_ID = _GOOGLE_OAUTH_CLIENT_ID;
export const RZP_KEY = _RZP_KEY;
export const S3FolderPath = s3FolderPath;