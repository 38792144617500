import red from "@material-ui/core/colors/red";
import { createMuiTheme } from "@material-ui/core/styles";
import proximaNova from "./assets/ProximaNova.otf";

const ProximaNova = {
  fontFamily: "ProximaNova",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('ProximaNova'),
    local('ProximaNova-Regular'),
    url(${proximaNova}) format('opentype')
  `,
};

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      //main: '#6f08f7',
      main: "#0b54f6",
      contrastText: "#fff",
    },
    secondary: {
      //main: '#08f76f',
      main: "#fe3e3e",
      contrastText: "#fff",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fafafa",
    },
  },
  typography: {
    fontFamily: [
      "ProximaNova",
      //"-apple-system",
      //"BlinkMacSystemFont",
      //'"Segoe UI"',
      //"Roboto",
      //'"Helvetica Neue"',
      //"Arial",
      //"sans-serif",
      //'"Apple Color Emoji"',
      //'"Segoe UI Emoji"',
      //'"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [ProximaNova],
      },
    },
  },
});

export default theme;
