import React, {useEffect, useContext, useState} from "react";
import {
    makeStyles,
    useTheme,
    Theme,
    createStyles,
} from "@material-ui/core/styles";
import {Grid, Paper, Typography, Box, TextField, Chip, Card, CardContent, CardActions, Button} from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SideBar from "./SideBar";
import instance from "../axios";
import Display1 from "./Display1";
import PortfolioDetails from "./PortfolioDetails";
import {useLocation} from "react-router-dom";
import {cleanOutput, clientMode} from "../shared/utils";
import {Autocomplete} from "@material-ui/lab";
import axios from "../axios";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            height: "100%",
            backgroundColor: "#fafafa",
            minHeight: "calc(100vh - 187px)",
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(2),
            backgroundColor: "#fafafa",
            [theme.breakpoints.up("md")]: {
                marginLeft: "37px",
                marginRight: "20px",
                backgroundColor: "#fafafa",
            },
        },
        display1: {
            // paddingBottom: "24px",
            backgroundColor: "#fafafa",
            [theme.breakpoints.up("md")]: {
                // paddingBottom: "84px",
            },
        },
        display1Heading: {
            fontSize: "20px",
            fontWeight: 600,
            padding: "5px 0px",
            [theme.breakpoints.up("md")]: {
                fontSize: "25px",
                padding: "10px 0px",
            },
        },
        display1Div: {
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
        },
        portfolios: {
            // paddingBottom: "24px",
            backgroundColor: "#fafafa",
            [theme.breakpoints.up("md")]: {
                // paddingBottom: "84px",
            },
        },
        portfolioHeading: {
            fontSize: "20px",
            fontWeight: 600,
            padding: "5px 0px",
            [theme.breakpoints.up("md")]: {
                fontSize: "25px",
                padding: "10px 0px",
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        section: {
            padding: "20px 20px",
            textDecoration: "none"
        }
    })
);

const AdvisoryFund = () => {
    const classes = useStyles();
    const location = useLocation();

    const [showLoading, setShowLoading] = useState(true);
    const [portfolioDesc, setPortfolioDesc] = useState({});
    const [portfolioList, setPortfolioList] = useState([]);
    const [inputValue, setInputValue] = React.useState([]);
    const [value, setValue] = React.useState<string | null>();

    const [redraw, setRedraw] = React.useState(false);

    function activateRedraw() {
        setRedraw(!redraw)
    }

    useEffect(() => {
        setShowLoading(true)
        instance.get('/portfolio/portfolioList')
            .then((res) => res.data)
            .then((data) => {
                setPortfolioList(data);
            })
            .catch((err) => {

            });
        activateRedraw()
    }, []);

    useEffect(() => {
        instance.get('/portfolio/get-public-portfolio')
            .then((res) => res.data)
            .then((data) => {
                setInputValue(data)
                setShowLoading(false)
            })
            .catch((err) => {

            });

    }, [redraw]);


    // function publicPortfolios() {
    //     let data={
    //         'portfolios':inputValue.map((item) => item.id)
    //     }
    //     axios
    //         .post("/portfolio/enable-public-portfolio", data)
    //         .then((res: any) => {
    //             if (res.status == 200)
    //                 alert('Successfully updated')
    //             if (res.status == 400)
    //                 alert("Unauthorized access, kindly contact admin");
    //             if (res.status == 500)
    //                 alert("Failure, kindly contact admin");
    //         })
    //         .catch((err: any) => {
    //             console.log(err)
    //             alert("Error, contact administrator");
    //         });
    // }


    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let data=portfolioDesc;
        data[event.target.id]=event.target.value;
        setPortfolioDesc(data)
    };

    function updateDescription(id: any) {
        let data={
            portfolioID:id,
            description:portfolioDesc[id]
        }
        axios
            .post("/portfolio/updatePortfolioDetails", data)
            .then((res: any) => {
                if (res.status == 200)
                    alert('Successfully updated')
                if (res.status == 400)
                    alert("Unauthorized access, kindly contact admin");
                if (res.status == 500)
                    alert("Failure, kindly contact admin");
            })
            .catch((err: any) => {
                console.log(err)
                alert("Error, contact administrator");
            });
    }

    function disableAccess(id:any) {
        setShowLoading(true)
        axios
            .post("/portfolio/disable-public-portfolio", {portfolioID:id})
            .then((res: any) => {
                if (res.status == 200) {
                    activateRedraw()
                }
                if (res.status == 400)
                    alert("Unauthorized access, kindly contact admin");
                if (res.status == 500)
                    alert("Failure, kindly contact admin");
            })
            .catch((err: any) => {
                console.log(err)
                alert("Error, contact administrator");
            });
    }

    function enableAccess(id) {
        setShowLoading(true)
        axios
            .post("/portfolio/enable-public-portfolio", {portfolioID:id})
            .then((res: any) => {
                if (res.status == 200) {
                    activateRedraw()
                }
                if (res.status == 400)
                    alert("Unauthorized access, kindly contact admin");
                if (res.status == 500)
                    alert("Failure, kindly contact admin");
            })
            .catch((err: any) => {
                console.log(err)
                alert("Error, contact administrator");
            });
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.display1}>
                    <div className={classes.display1Heading}>Advisory Funds</div>
                    {/* <span>
            <Typography>Change your risk profile: <a href="/risk-profiler">Click here</a></Typography>
            <Typography>Complete KYC: <a href="/kyc">Click here</a></Typography>
          </span> */}
                    <Grid container direction="column" spacing={3}>
                            <Box border={1} borderColor="grey.500" borderRadius={16} className={classes.section}>
                                <Typography>Convert your portfolios to public for your client's access</Typography>
                                <br/>
                                <Autocomplete
                                    id="tags-standard"
                                    options={portfolioList}
                                    getOptionLabel={(option) => option.name}
                                    style={{width: 300}}
                                    value={value}
                                    onChange={(event: any, newValue: string | null) => {
                                        if(newValue)
                                        enableAccess(newValue.id);
                                        setValue("")
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Enter Portfolio"
                                        />
                                    )}
                                />
                                <Grid container direction="row" spacing={3}>
                                    <Grid item xs={10} md={10} >
                                    <Box className={classes.section}>
                                        <br/>
                                        <Grid container direction="row" spacing={3} alignItems="center">
                                            {inputValue && inputValue.map((item, index) =>
                                                (
                                                    <Grid item xs={12}>
                                                        <Paper elevation={3}>
                                                            <Card className={classes.cardRoot}>
                                                                {/*<CardActionArea>*/}
                                                                {/*<CardMedia*/}
                                                                {/*    className={classes.media}*/}
                                                                {/*    image="/static/images/cards/contemplative-reptile.jpg"*/}
                                                                {/*    title="Contemplative Reptile"*/}
                                                                {/*/>*/}
                                                                <CardContent>
                                                                    <Typography gutterBottom variant="h5" component="h2">
                                                                        <Grid item xs={8} md={8}>
                                                                            <Autocomplete
                                                                                disabled={true}
                                                                                id="tags-standard"
                                                                                options={portfolioList}
                                                                                getOptionLabel={(option) => option.name}
                                                                                style={{width: 300}}
                                                                                inputValue={item.name}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        variant="outlined"
                                                                                        label="Enter Portfolio"
                                                                                        // placeholder="Enter portfolio name"
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </Grid>
                                                                    </Typography>
                                                                    <Grid item xs={4} md={6}>
                                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                                        <TextField
                                                                            id={item.id}
                                                                            label="Enter description"
                                                                            multiline
                                                                            rowsMax={4}
                                                                            defaultValue={item.details.description}
                                                                            onChange={handleDescriptionChange}
                                                                            variant="outlined"
                                                                        />

                                                                        {/*{item.details.description}*/}
                                                                    </Typography>
                                                                    </Grid>
                                                                </CardContent>
                                                                {/*</CardActionArea>*/}
                                                                <CardActions>
                                                                    {/*<Button size="small" color="primary">*/}
                                                                    {/*    Share*/}
                                                                    {/*</Button>*/}
                                                                    <Button size="small" color="primary"
                                                                            onClick={()=>updateDescription(item.id)}
                                                                    >
                                                                        Update Description
                                                                    </Button>
                                                                    <Button size="small" color="primary"
                                                                            onClick={()=>disableAccess(item.id)}
                                                                    >
                                                                        Remove Access
                                                                    </Button>
                                                                </CardActions>
                                                            </Card>
                                                        </Paper>
                                                    </Grid>
                                                )
                                            )}
                                        </Grid>
                                    </Box>
                                    </Grid>

                                    {/*<Grid item xs={2} md={2} className={classes.buttonsDiv}>*/}
                                    {/*    <button*/}
                                    {/*        className={classes.button}*/}
                                    {/*        onClick={() => publicPortfolios()}>*/}
                                    {/*        Update List*/}
                                    {/*    </button>*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </Box>

                    </Grid>
                </div>
            </div>

            <Backdrop className={classes.backdrop} open={showLoading} onClick={() => {
            }}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    );
};
export default AdvisoryFund;
