import axios from "axios";
import { getToken, saveAuth } from './shared/utils';

export let baseURL: string = "http://localhost:8000/api";
// export let baseURL: string = "https://makemyfund.in/api";

if (process.env.NODE_ENV === "production") {
  baseURL = "/api";
}

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  function (config) {
    if (getToken() !== null){
      config.headers = {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + getToken()
      };
    }

    let url = config.url;
    if (!url) {
      url = '';
    }
    url = url.toLowerCase();

    // if (url.includes("simulation")){
    //   alert("We're refining some things and will be back shortly. Simulations will not work till then.")
    // }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response !== undefined && error.response.status === 401) {
    saveAuth(undefined);
    // window.open("/", "_self");
  } else {
    // window.open("/", "_self");
    if(error.response.data.error){
      error.message = error.response.data.error
    }else{
      for(let x in error.response.data){
        error.message = error.response.data[`${x}`][0]
      }
    }
   }
   return Promise.reject(error);
});

export default instance;
