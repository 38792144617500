import React, { useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core/styles";
import "./App.css";
import { store, SigninProvider } from "./SignIn";
import AppWithSideBar from "./AppWithSideBar";
import AlertProvider from "./AlertProvider";
import AlertMessage from "./components/alert/AlertMessage";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <SigninProvider>
          <AlertProvider>
            <AppWithSideBar />
            <AlertMessage />
          </AlertProvider>
        </SigninProvider>
      </Router>
    </ThemeProvider>
  );
}
