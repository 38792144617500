import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import CSelect from "../atoms/CSelect";
import SliderP from "../atoms/SliderP";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { readFromLocal } from "../../shared/utils";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    padding: "27px 0px 19px 0px",
    backgroundColor: "#fff",
    marginTop: "20px",
    maxWidth: "1359px",
    margin: "0px auto",
    justifyContent: "center",
    borderRadius: "10px",
    [theme.breakpoints.up("md")]: {
      marginTop: "39px",
      padding: "27px 0px 39px 0px",
    },
  },
  container: {
    //padding: "20px 16px",
    width: "100%",
    [theme.breakpoints.up("md")]: {},
  },
  centeredRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  moreOptions: {
    display: "flex",
    marginTop: theme.spacing(1),
  },
  item: {
    padding: "10px",
  },
  heading: {
    fontSize: "14px",
    marginBottom: "11px",
    fontWeight: 600,
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
    },
  },
  sliderDiv: {
    display: "flex",
    alignItems: "center",
    justifyContnet: "center",
  },
  input: {
    border: "1px solid #e0e0e0",
    height: "44px",
    minWidth: "60px",
    maxWidth: "70px",
    textAlign: "center",
    backgroundColor: "#FAFAFA",
    borderRadius: "7px",
    fontSize: "18px",
    marginLeft: "20px",
  },
  description: {
    fontSize: "12px",
    color: "#767676",
    marginLeft: "30px",
  },
  simulateButton: {
    backgroundColor: "#0b54f6",
    color: "#ffffff",
    fontSize: "14px",
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#0b54f6",
      color: "#ffffff",
      borderRadius: "10px",
      boxShadow: "0px 20px 30px #cddbfd",
      textDecoration: "none",
      fontSize: "18px",
      fontWeight: 600,
      padding: "0px 24px",
      height: "54px",
      lineHeight: "54px",
      width: "150px",
      minWidth: "150px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "&:hover": {
      backgroundColor: "#0b54f6",
      color: "#ffffff",
    },
    "&:active": {
      backgroundColor: "#0b54f6",
      color: "#ffffff",
    },
  },
  simulateLabel: {
    //fontSize: "14px",
    //fontWeight: 600,
    marginLeft: "10px",
    textTransform: "none",
  },
}));
const startRankOptions = [
  { name: "1", value: 1 },
  { name: "51", value: 51 },
  { name: "101", value: 101 },
  { name: "201", value: 201 },
];
const endRankOptions = [
  { name: "50", value: 50 },
  { name: "100", value: 100 },
  { name: "200", value: 200 },
  { name: "500", value: 500 },
];
const frequencyOptions = [
  { name: "Quarterly", value: "QUARTERLY" },
  { name: "Monthly", value: "MONTHLY" },
  // { name: "Yearly", value: "YEARLY" },
];
const marketCapOptions = [
  { name: "Blue Chip(Top 50)", value: "BLUE_CHIP" },
  { name: "Large Cap(Top 100)", value: "LARGE_CAP" },
  { name: "Top 200", value: "TOP_200" },
  { name: "Mid Cap(Top 101-500)", value: "MID_CAP" },
  { name: "Multi Cap(Top Top 500)", value: "MULTI_CAP" },
];
interface Universe {
  starting_rank: number;
  ending_rank: number;
  portfolio_size: number | number[];
  rebalancing_frequency: string;
}
type handler = (event: any) => void;
type sliderHandler = (event: any, newValue: number | number[]) => void;
interface Props {
  universe: Universe;
  handleMarketCap: handler;
  handleStart: handler;
  handleEnd: handler;
  handleFrequency: handler;
  handleSizeInput: handler;
  handleSize: sliderHandler;
  handleClick: handler;
  showSimulateButton: boolean;
}

const SimulateOptions = (props: Props) => {
  const classes = useStyles();
  const {
    market_cap,
    starting_rank,
    ending_rank,
    portfolio_size,
    rebalancing_frequency,
  } = props.universe;
  const {
    handleMarketCap,
    handleStart,
    handleEnd,
    handleSize,
    handleSizeInput,
    handleFrequency,
    handleClick,
    showSimulateButton
  } = props;

  const [showAdvanced, setShowAdvanced] = React.useState(false);

  return (
    <div className={classes.container}>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.description}>
          <Typography>
            Choose your investment universe, portfolio size and rebalancing
            frequency.
          </Typography>
        </Grid>
        <Grid item xs={false} md={1} />
        <Grid item xs={11} md={3} className={classes.item}>
          {/*market cap*/}
          <div className={classes.heading}>Investment Universe</div>
          <CSelect
            options={marketCapOptions}
            value={market_cap}
            handleValue={handleMarketCap}
          />
          <Grid item className={classes.centeredRow}>
            <div
              onClick={() => setShowAdvanced(!showAdvanced)}
              className={classes.moreOptions}
            >
              <Typography>More options for universe</Typography>
              <IconButton aria-label="more-options" size="small">
                {showAdvanced ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
              </IconButton>
            </div>
          </Grid>
          <Grid container className={classes.centeredRow}>
            {/* <CButton handleClick={() => setShowAdvanced(!showAdvanced)} label="Show Advanced" /> */}
            {showAdvanced && (
              <>
                <Grid item xs={12} md={12} className={classes.item}>
                  {/*starting rank*/}
                  <div className={classes.heading}>
                    Starting Market Cap Rank
                  </div>
                  <CSelect
                    options={startRankOptions}
                    value={starting_rank}
                    handleValue={handleStart}
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.item}>
                  {/*ending rank*/}
                  <div className={classes.heading}>Ending Market Cap Rank</div>
                  <CSelect
                    options={endRankOptions}
                    value={ending_rank}
                    handleValue={handleEnd}
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.item}>
                  {/*rebalance frequency*/}
                  <div className={classes.heading}>Rebalance Frequency</div>
                  <CSelect
                    options={frequencyOptions}
                    value={rebalancing_frequency}
                    handleValue={handleFrequency}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        
        <Grid item xs={11} md={4} className={classes.item}>
          {/*portfolio size*/}
          <div className={classes.heading}>Portfolio Size</div>
          <div className={classes.sliderDiv}>
            <SliderP
              min={25}
              max={ending_rank - starting_rank + 1}
              step={5}
              value={portfolio_size}
              handleValue={handleSize}
            />
            <input
              className={classes.input}
              value={portfolio_size}
              type="number"
              min={starting_rank}
              max={ending_rank}
              onChange={handleSizeInput}
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.description}>
            <div
              className={classes.moreOptions}
            >
              {readFromLocal ("universe_exclusions") === undefined ?
              <Typography>To exclude/include specific sectors, business houses and companies, click <a href="/refine_universe">here</a></Typography>
              :
              <Typography>Universe exclusions/inclusions are applied to sectors, business houses and companies, click <a href="/refine_universe">here</a> to refine.</Typography>
              }
              
            </div>
          </Grid>

          {showSimulateButton && <Grid item>
            <Button className={classes.simulateButton} onClick={handleClick}>
              <CachedIcon />
              <span className={classes.simulateLabel}>Simulate</span>
            </Button>
          </Grid>}
      </Grid>
    </div>
  );
};

export default SimulateOptions;
