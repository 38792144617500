import React from "react";
import { Backdrop, Paper, Typography, Grid } from "@material-ui/core";
import Animation from "../../assets/simulate_animation.gif";


const SimulateAnimation: React.FC<{ show: boolean, text?: string }> = (props) => {
  const { show, text } = props;

  const loadingText = text !== undefined? text : 'Simulating...'
  
  return (
    <Backdrop style={{zIndex: 999, color: '#fff',}} open={show} onClick={() => {}}>
        <Paper style={{
            padding: 10,
            borderRadius: 10,
        }}>
            <Grid container direction="column">
            <Grid item>
            <img src={Animation} height={200}/></Grid>
            <Grid item container justify="center">
            <Typography variant="h5">{loadingText}</Typography></Grid>
            </Grid>
        </Paper>
    </Backdrop>
    );
};

export default SimulateAnimation;
