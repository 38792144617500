import React, {useState, useEffect} from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Grid, Typography, Button} from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import {saveToLocal, readFromLocal} from "../../shared/utils";
import SimulateOptions from "./SimulateOptions";
import SimulateGraph from "./SimulateGraph";
import dummy from "../../pages/DummyData";
import instance from "../../axios";
import SimulateAnimation from "./SimulateAnimation";
import axios from "../../axios";
import {simulationParams} from "../../shared/utils"

interface Universe {
    market_cap: string;
    starting_rank: number;
    ending_rank: number;
    portfolio_size: number | number[];
    rebalancing_frequency: string;
}

interface Performance {
    returns_pa: number | string;
    benchmark_returns_pa: number | string;
    outperformance_pa: number | string;
    total_transaction_cost: number | string;
}

type Data = [number, number][];

const mCapMapping: any = {
    "BLUE_CHIP": {startRank: 1, endRank: 50},
    "LARGE_CAP": {startRank: 1, endRank: 100},
    "TOP_200": {startRank: 1, endRank: 200},
    "MID_CAP": {startRank: 101, endRank: 500},
    "MULTI_CAP": {startRank: 1, endRank: 500},
}

const initUniverse: Universe = {
    market_cap: "TOP_200",
    starting_rank: 1,
    ending_rank: 200,
    portfolio_size: 50,
    rebalancing_frequency: "QUARTERLY",
};

const initPerformance: Performance = {
    returns_pa: "",
    benchmark_returns_pa: "",
    outperformance_pa: "",
    total_transaction_cost: "",
};
const initData: Data = [{data: []}, {data: []}];


const useStyles = makeStyles((theme: Theme) => ({
    simulateButton: {
        backgroundColor: "#0b54f6",
        color: "#ffffff",
        fontSize: "14px",
        [theme.breakpoints.up("md")]: {
            backgroundColor: "#0b54f6",
            color: "#ffffff",
            borderRadius: "10px",
            boxShadow: "0px 20px 30px #cddbfd",
            textDecoration: "none",
            fontSize: "18px",
            fontWeight: 600,
            padding: "0px 24px",
            height: "54px",
            lineHeight: "54px",
            width: "150px",
            minWidth: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        "&:hover": {
            backgroundColor: "#0b54f6",
            color: "#ffffff",
        },
        "&:active": {
            backgroundColor: "#0b54f6",
            color: "#ffffff",
        },
    },
    simulateLabel: {
        //fontSize: "14px",
        //fontWeight: 600,
        marginLeft: "10px",
        textTransform: "none",
    },
    stepLabel: {
        fontSize: "16px",
        color: "#222222",
        margin: "20px",
        fontWeight: "bold",
        [theme.breakpoints.up("md")]: {
            // display: "none",
        },
    },
}));

const SimulationByConfig = (props: any) => {
    const classes = useStyles();
    const [universe, setUniverse] = useState<Universe>(initUniverse);
    const [performance, setPerformance] = useState<Performance>(initPerformance);
    const [yoyPerformance, setYoyPerformance] = useState([]);
    const [step, setStep] = useState(true);
    const [csvData, setCsvData] = useState([]);
    const [data, setData] = useState<Data>(initData);
    const [showSimulateAnimation, setShowSimulateAnimation] = useState(false);

    useEffect(() => {
        if (
            universe.ending_rank - universe.starting_rank + 1 <
            universe.portfolio_size
        ) {
            setUniverse({
                ...universe,
                portfolio_size: universe.ending_rank - universe.starting_rank + 1,
            });
        }
        if (props.setUniverse !== undefined)
            props.setUniverse(universe);
    }, [universe]);

    useEffect(() => {
        if (props.stepMode == true) {
            setStep(false)
        }
        setShowSimulateAnimation(true);
        if (props.configId)
            axios
                .get("/portfolio/get-portfolio-config?id=" + props.configId)
                .then((res: any) => {
                    let aleph = JSON.parse((res.data.config.replaceAll("\'", "\"")).replaceAll("True", "true").replaceAll("False", "false"))
                    handleSimulation(aleph)
                })
        else
            setShowSimulateAnimation(false);
    }, [])

    const handleMarketCap = (event: any) => {
        const mCap = event.target.value;

        let _universe = {...universe};
        _universe.market_cap = mCap;
        _universe.starting_rank = mCapMapping[mCap].startRank;
        _universe.ending_rank = mCapMapping[mCap].endRank;
        _universe.portfolio_size = mCapMapping[mCap].endRank - mCapMapping[mCap].startRank + 1;
        setUniverse(_universe);
    };
    const handleStart = (event: any) => {
        const v = event.target.value;
        //
        if (v < universe.ending_rank) {
            setUniverse({...universe, starting_rank: Number(v)});
        }
    };
    const handleEnd = (event: any) => {
        const v = event.target.value;
        //
        if (v > universe.starting_rank) {
            setUniverse({...universe, ending_rank: Number(v)});
        }
    };
    const handleFrequency = (event: any) => {
        const v = event.target.value;
        //
        setUniverse({...universe, rebalancing_frequency: v});
    };
    const handleSize = (event: any, newValue: number | number[]) => {
        //
        setUniverse({...universe, portfolio_size: newValue});
    };
    const handleSizeInput = (event: any) => {
        const v = event.target.value;
        //
        if (v <= universe.ending_rank && v >= universe.starting_rank) {
            setUniverse({...universe, portfolio_size: event.target.value});
        }
    };
    const handleSimulation = (aleph) => {
        let final = {
            ...props,
            universe_selection: universe,
        };
        let link = ""
        if (aleph['factors']) {
            link = simulationParams['beta']
            delete aleph['def2']
            final['factors'] = aleph['factors']
            final['universe_selection'] = aleph['universe_selection']
            saveToLocal("_highest_returns_factors", aleph['factors']);
            saveToLocal("universe", aleph['universe_selection']);
            props.setType("beta")
        }
        if (aleph['sectors']) {
            link = simulationParams['themes']
            delete aleph['def2']
            final['sectors'] = aleph['sectors']
            final['universe_selection'] = aleph['universe_selection']
            saveToLocal("_sectors", aleph['sectors']);
            saveToLocal("universe", aleph['universe_selection']);
            props.setType("themes")
        }
        if (aleph['sub_sector_tilts']) {
            link = simulationParams['themes2']
            delete aleph['def2']
            final['sub_sector_tilts'] = aleph['sub_sector_tilts']
            final['universe_selection'] = aleph['universe_selection']
            saveToLocal("_sub_sector_tilts", aleph['sub_sector_tilts']);
            saveToLocal("universe", aleph['universe_selection']);
            props.setType("themes2")
        }

        //save last call to local storage
        // saveToLocal("lastCall", {data: final, link: props.link});
        //
        // let universe_exclusions = readFromLocal("universe_exclusions");
        // if (universe_exclusions !== undefined) {
        //     final.universe_selection.universe_exclusions = universe_exclusions;
        // }
        instance
            .post(link, final)
            .then((res: any) => {

                //save the result to the local storage for the results page
                saveToLocal("simulationResult", res.data);
                setPerformance(res.data.result.performance);
                setData(res.data.result.historical_returns);
                setCsvData(res.data.result.historical_returns_downloadable);
                setYoyPerformance(res.data.result.performance_yearly);
                setShowSimulateAnimation(false);
                // window.location.href = "#simulationGraph";
            })
            .catch((err: any) => {

                alert('Could not complete simulation due to an error.')
                setShowSimulateAnimation(false);
            });
    };

    return (
        <>
            {(step || props.stepNumber == 2) &&
            <SimulateOptions
                universe={universe}
                handleMarketCap={handleMarketCap}
                handleStart={handleStart}
                handleEnd={handleEnd}
                handleFrequency={handleFrequency}
                handleSize={handleSize}
                handleSizeInput={handleSizeInput}
                handleClick={handleSimulation}
                showSimulateButton={props.showSimulateButton}
            />}
            {props.showStep !== undefined && <div className={classes.stepLabel}>Step 3:</div>}

            {(step && <div style={{display: "flex", justifyContent: "center"}}>
                <Button className={classes.simulateButton} onClick={handleSimulation}>
                    <CachedIcon/>
                    <span className={classes.simulateLabel}>Simulate</span>
                </Button>
            </div>)}
            {(step || props.stepNumber == 3) &&
            <div>
                <SimulateGraph
                    performance={performance}
                    data={data}
                    handleClick={handleSimulation}
                    csvData={csvData}
                    yoyPerformance={yoyPerformance}
                    showStep={props.showStep}
                />
                <SimulateAnimation show={showSimulateAnimation}/>
            </div>
            }
        </>
    );
};

export default SimulationByConfig;
