/** @format */

import React, { useContext } from "react";
import {
	Route,
	BrowserRouter as Router,
	Switch,
	Redirect,
} from "react-router-dom";
// @ts-ignore
import Loadable from "react-loadable";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { store, SigninProvider } from "./SignIn";
import ClientAccount from "./pages/ClientAccount";
import AdvisoryFund from "./pages/AdvisoryFund";
import ClientFund from "./pages/ClientFund";
import ClientFundPreview from "./pages/ClientFundPreview";
import { checkRole, readFromLocal } from "./shared/utils";
import AdoptedFund from "./pages/AdoptedFund";

const Loading = () => (
	<div
		style={{
			height: "80vh",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		}}>
		<h3>Loading...</h3>
	</div>
);

const Main = Loadable({
	loader: () => import("./pages/Main"),
	loading: Loading,
});
const InvestOptions = Loadable({
	loader: () => import("./pages/InvestOptions"),
	loading: Loading,
});
const MyValues = Loadable({
	loader: () => import("./pages/MyEthics"),
	loading: Loading,
});
const MyEthicsSectorAllocations = Loadable({
	loader: () => import("./pages/MyEthicsSectorAllocations"),
	loading: Loading,
});
const UniverseRefine = Loadable({
	loader: () => import("./pages/UniverseRefine"),
	loading: Loading,
});
const PVEFResult = Loadable({
	loader: () => import("./pages/PVEFResult"),
	loading: Loading,
});
const SimplePortfolioResult = Loadable({
	loader: () => import("./pages/SimplePortfolioResult"),
	loading: Loading,
});
const MyThemes = Loadable({
	loader: () => import("./pages/MyThemes"),
	loading: Loading,
});
const BondMarketOrder = Loadable({
	loader: () => import("./pages/BondMarketOrder"),
	loading: Loading,
});
const BondMarketTransactionManage = Loadable({
	loader: () => import("./pages/BondMarketTransactionManage"),
	loading: Loading,
});
const MyThemematicPVEF = Loadable({
	loader: () => import("./pages/MyThemematicPVEF"),
	loading: Loading,
});
const MyThemesSectorAllocation = Loadable({
	loader: () => import("./pages/MyThemematicPVEFSectorAllocation"),
	loading: Loading,
});
const MyThemesSectorSpecific = Loadable({
	loader: () => import("./pages/MyThemesSectorSpecific"),
	loading: Loading,
});
const Dashboard = Loadable({
	loader: () => import("./pages/Dashboard"),
	loading: Loading,
});
const ClientPortfolios = Loadable({
	loader: () => import("./pages/ClientPortfolios"),
	loading: Loading,
});
const MyContext = Loadable({
	loader: () => import("./pages/MyGoals"),
	loading: Loading,
});
const MyContextSelect = Loadable({
	loader: () => import("./pages/MyGoalsSelect"),
	loading: Loading,
});
const Login = Loadable({
	loader: () => import("./pages/Login"),
	loading: Loading,
});
const Register = Loadable({
	loader: () => import("./pages/Register"),
	loading: Loading,
});
const ChangePassword = Loadable({
	loader: () => import("./pages/ChangePassword"),
	loading: Loading,
});
const VerifyEmail = Loadable({
	loader: () => import("./pages/VerifyEmail"),
	loading: Loading,
});
const CreatePortfolio = Loadable({
	loader: () => import("./pages/CreatePortfolio"),
	loading: Loading,
});
const CreateCustomPortfolio = Loadable({
	loader: () => import("./components/CustomPortfolio/CreateCustomPortfolio"),
	loading: Loading,
});
const ViewCustomPortfolio = Loadable({
	loader: () => import("./components/CustomPortfolio/ClientCustomPortfolio"),
	loading: Loading,
});
const ExecutePortfolio = Loadable({
	loader: () => import("./pages/ExecutePortfolio"),
	loading: Loading,
});
const AcceptTerms = Loadable({
	loader: () => import("./pages/AcceptTerms"),
	loading: Loading,
});
const TeamSettings = Loadable({
	loader: () => import("./pages/TeamSettings"),
	loading: Loading,
});
const CashCallManagement = Loadable({
	loader: () => import("./pages/CashCallManagement"),
	loading: Loading,
});
const Account = Loadable({
	loader: () => import("./pages/Account"),
	loading: Loading,
});
const RiskProfiler = Loadable({
	loader: () => import("./components/account/RiskProfiler"),
	loading: Loading,
});
const KYC = Loadable({
	loader: () => import("./components/account/KYC"),
	loading: Loading,
});
const TraderTools = Loadable({
	loader: () => import("./pages/TraderTools"),
	loading: Loading,
});
const Trade = Loadable({
	loader: () => import("./pages/Trade"),
	loading: Loading,
});
const MutualFunds = Loadable({
	loader: () => import("./pages/MutualFunds"),
	loading: Loading,
});
const MultiMutualFund = Loadable({
	loader: () => import("./pages/MultiMutualFund"),
	loading: Loading,
});
const ClientFunds = Loadable({
	loader: () => import("./pages/ClientFund"),
	loading: Loading,
});
const PublicFund = Loadable({
	loader: () => import("./pages/PublicFund"),
	loading: Loading,
});
const Bond = Loadable({
	loader: () => import("./pages/BondList"),
	loading: Loading,
});
const AdoptedFunds = Loadable({
	loader: () => import("./pages/AdoptedFund"),
	loading: Loading,
});
const MutualFundDetail = Loadable({
	loader: () => import("./components/mutualFunds/MutualFundDetail"),
	loading: Loading,
});
const MultiAssetETFs = Loadable({
	loader: () => import("./pages/MultiAssetStep"),
	loading: Loading,
});
const VerifyWalletTransaction = Loadable({
	loader: () => import("./pages/VerifyTransaction"),
	loading: Loading,
});
const VerifyMoneyTransaction = Loadable({
	loader: () => import("./pages/VerifyMoneyTransaction"),
	loading: Loading,
});

const DirectBonds = Loadable({
	loader: () => import("./pages/EFundPortfolio"),
	loading: Loading,
});
const CryptoFund = Loadable({
	loader: () => import("./pages/CryptoFund"),
	loading: Loading,
});

const Wallet = Loadable({
	loader: () => import("./pages/Wallet"),
	loading: Loading,
});

const ApplyWallet = Loadable({
	loader: () => import("./pages/ApplyWallet"),
	loading: Loading,
});

const BankDetails = Loadable({
	loader: () => import("./pages/BankDetails"),
	loading: Loading,
});

const HighestReturnsStep = Loadable({
	loader: () => import("./pages/HighestReturnsStep"),
	loading: Loading,
});
const SimulationResult = Loadable({
	loader: () => import("./pages/SimulationResult"),
	loading: Loading,
});
const MyClients = Loadable({
	loader: () => import("./pages/MyClients"),
	loading: Loading,
});
const OnboardParter = Loadable({
	loader: () => import("./pages/OnboardPartner"),
	loading: Loading,
});
const BlogPost = Loadable({
	loader: () => import("./pages/BlogPost"),
	loading: Loading,
});
const BlogDetails = Loadable({
	loader: () => import("./pages/BlogDetails"),
	loading: Loading,
});

const AppWithoutSideBar = (props: any) => {
	const isClient = checkRole("Client");
	const { toggleDrawer, sideBarOpen } = props;
	const globalStore = useContext(store);
	return (
		<>
			<Header sideBarOpen={sideBarOpen} toggleDrawer={toggleDrawer} />
			<Switch>
				<Route path='/refine_universe' component={UniverseRefine} />
				<Route path='/ethics/sectors' component={MyEthicsSectorAllocations} />
				<Route path='/ethics' component={MyValues} />
				<Route path='/blog' exact component={BlogPost} />
				<Route path='/blog/:slug' exact component={BlogDetails} />
				<Route path='/highest_returns'>
					<Redirect to='/highest-returns/1' />
				</Route>
				<Route
					path='/themeatic_pvef/sectors'
					component={MyThemesSectorAllocation}
				/>
				<Route path='/themeatic_pvef' component={MyThemematicPVEF} />
				<Route
					path='/theme_sector_specific'
					component={MyThemesSectorSpecific}
				/>
				<Route path='/themes' component={MyThemes} />
				<Route path='/goals/select/:goal' exact component={MyContextSelect} />
				<Route path='/goals' component={MyContext} />

				<Route path='/portfolio/:prev' component={SimplePortfolioResult} />
				<Route path='/pvefresult/:prev' component={PVEFResult} />
				<Route path='/login/:next' component={Login} />
				<Route path='/login' component={Login} />
				<Route path='/register/:next' component={Register} />
				<Route path='/register/:next/:mandate' component={Register} />
				<Route path='/register' component={Register} />
				<Route path='/changepassword' component={ChangePassword} />
				<Route path='/verifyemail' component={VerifyEmail} />
				<Route path='/create_portfolio' component={CreatePortfolio} />
				<Route
					path='/create-custom-portfolio'
					component={CreateCustomPortfolio}
				/>
				<Route path='/viewCP/:portfolio_id' component={ViewCustomPortfolio} />
				<Route path='/client-portfolio' component={ClientPortfolios} />
				<Route path='/investment-agreement/:next' component={AcceptTerms} />
				<Route path='/investment-agreement' component={AcceptTerms} />
				<Route path='/team-details' component={TeamSettings} />
				<Route path='/execute/:portfolio_id' component={ExecutePortfolio} />

				<Route path='/cash-call/:id' component={CashCallManagement} />
				<Route path='/cash-call' component={CashCallManagement} />

				<Route path='/portfolios/:id' component={Dashboard} />
				<Route path='/dashboard' component={Dashboard} />
				<Route path='/account' component={Account} />
				<Route path='/client-account' component={ClientAccount} />
				<Route path='/advisoryFund' component={AdvisoryFund} />
				<Route
					path='/admin/verify-wallet-transaction'
					component={VerifyWalletTransaction}
				/>
				<Route
					path='/admin/verify-money-transaction'
					component={VerifyMoneyTransaction}
				/>
				<Route path='/clientFundPreview' component={ClientFundPreview} />
				<Route path='/fundPreview/:id' component={ClientFundPreview} />
				<Route path='/risk-profiler' component={RiskProfiler} />
				<Route path='/kyc' component={KYC} />

				<Route path='/tradertools' component={TraderTools} />
				<Route path='/trade/:portfolioId' component={Trade} />
				<Route
					path='/mutual-funds/multi-mutual-fund'
					component={MultiMutualFund}
				/>
				<Route path='/mutual-funds/:portfolioId' component={MutualFundDetail} />
				<Route path='/mutual-funds/' component={MutualFunds} />

				<Route path='/multi-asset/:stepNumber' component={MultiAssetETFs} />
				<Route
					path='/highest-returns/:stepNumber'
					component={HighestReturnsStep}
				/>
				<Route path='/simulation-result' component={SimulationResult} />

				<Route
					path='/invest'
					exact
					component={isClient ? ClientFunds : InvestOptions}
				/>
				<Route path='/publicFunds' exact component={PublicFund} />
				<Route path='/Bonds' exact component={Bond} />
				<Route path='/tokenBonds' exact component={DirectBonds} />
				{/*<Route path="/CryptoFunds" exact component={CryptoFund} />*/}
				<Route path='/tokenBonds/sell' exact>
					<DirectBonds mode={"sell"} />
				</Route>
				<Route path='/my-wallet' exact component={Wallet} />
				<Route path='/apply-wallet' exact component={ApplyWallet} />
				<Route path='/transfer' exact component={BankDetails} />
				<Route path='/publicFunds/:team' exact component={PublicFund} />
				<Route
					path='/myFunds'
					exact
					component={isClient ? AdoptedFunds : Dashboard}
				/>
				{/*<Route path="/clientFund" component={ClientFund} />*/}

				<Route path='/instrument-order' component={BondMarketOrder} />
				<Route
					path='/bond-market-transaction'
					component={BondMarketTransactionManage}
				/>
				<Route path='/onboard-partner' component={OnboardParter} />
				<Route path='/clients' exact component={MyClients} />
				<Route path='/' exact component={Main} />
				<Route path='/:team' exact component={Main} />
			</Switch>
			<Footer />
		</>
	);
};
export default AppWithoutSideBar;
