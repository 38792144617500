import React, {useEffect, useContext, useState} from "react";
import {
    makeStyles,
    useTheme,
    Theme,
    createStyles,
} from "@material-ui/core/styles";
import {Grid, Paper, Typography, Box, TextField, Chip} from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SideBar from "./SideBar";
import instance from "../axios";
import Display1 from "./Display1";
import PortfolioDetails from "./PortfolioDetails";
import {useLocation} from "react-router-dom";
import {cleanOutput, clientMode} from "../shared/utils";
import {Autocomplete} from "@material-ui/lab";
import axios from "../axios";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            height: "100%",
            backgroundColor: "#fafafa",
            minHeight: "calc(100vh - 187px)",
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(2),
            backgroundColor: "#fafafa",
            [theme.breakpoints.up("md")]: {
                marginLeft: "37px",
                marginRight: "20px",
                backgroundColor: "#fafafa",
            },
        },
        display1: {
            // paddingBottom: "24px",
            backgroundColor: "#fafafa",
            [theme.breakpoints.up("md")]: {
                // paddingBottom: "84px",
            },
        },
        display1Heading: {
            fontSize: "20px",
            fontWeight: 600,
            padding: "5px 0px",
            [theme.breakpoints.up("md")]: {
                fontSize: "25px",
                padding: "10px 0px",
            },
        },
        display1Div: {
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
        },
        portfolios: {
            // paddingBottom: "24px",
            backgroundColor: "#fafafa",
            [theme.breakpoints.up("md")]: {
                // paddingBottom: "84px",
            },
        },
        portfolioHeading: {
            fontSize: "20px",
            fontWeight: 600,
            padding: "5px 0px",
            [theme.breakpoints.up("md")]: {
                fontSize: "25px",
                padding: "10px 0px",
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        section: {
            padding: "20px 20px",
            textDecoration: "none"
        }
    })
);

const ClientAccount = () => {
    const classes = useStyles();
    const location = useLocation();

    const [showLoading, setShowLoading] = useState(true);
    const [userDetails, setUserDetails] = useState({});
    const [advDetails, setAdvDetails] = useState({});
    const [portfolioList, setPortfolioList] = useState([]);
    const [advPortfolio, setAdvPortfolio] = React.useState<string | null>(null);
    const [inputValue, setInputValue] = React.useState([]);

    useEffect(() => {
        instance
            .get("/account/client-account-details?id=" + location.state.id)
            .then((res) => res.data)
            .then((data) => {
                setUserDetails(data['personal'])
                setAdvDetails(data['data'])
                setShowLoading(false);
            })
            .catch((err) => {

            });
        instance.get('/portfolio/portfolioList')
            .then((res) => res.data)
            .then((data) => {
                setPortfolioList(data);
            })
            .catch((err) => {

            });
        instance.get('/portfolio/getpriv-client-list?id='+location.state.id).then((res) => res.data)
            .then((data) => {
            })
            .catch((err) => {

            });
    }, []);

    function updatePortfolioList() {
        let data={
            'client':userDetails['id'],
            'portfolios':inputValue.map((item) => item.id)
        }
        axios
            .post("/portfolio/export-client-list", data)
            .then((res: any) => {
                if (res.status == 200)
                    alert('Successfully updated')
                if (res.status == 400)
                    alert("Unauthorized access, kindly contact admin");
                if (res.status == 500)
                    alert("Failure, kindly contact admin");
            })
            .catch((err: any) => {
                console.log(err)
                alert("Error in export client list, contact administrator");
            });
    }

    function onTagsChange(e,values) {
        setInputValue(values)
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.display1}>
                    <div className={classes.display1Heading}>Account</div>
                    {/* <span>
            <Typography>Change your risk profile: <a href="/risk-profiler">Click here</a></Typography>
            <Typography>Complete KYC: <a href="/kyc">Click here</a></Typography>
          </span> */}
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Box border={1} borderColor="grey.500" borderRadius={16} className={classes.section}>
                                <Grid container>
                                    <Grid item xs={6} md={3}>
                                        <Typography>Email Id: </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={9}>
                                        <Typography>{userDetails.email}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Typography>Type of Client: </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={9}>
                                        <Typography>{clientMode[advDetails.type]}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Typography>Signed up on: </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={9}>
                                        <Typography>{cleanOutput(advDetails.acceptedDate)}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item>
                            <Box border={1} borderColor="grey.500" borderRadius={16} className={classes.section}>
                                <Typography>Export from your created portfolio to only this client</Typography>
                                <br />
                                {/*<Typography>Portfolios proposed but not subscribed</Typography>*/}
                                {/*<Typography>Propose a portfolio to this client</Typography>*/}
                                {/*<Grid container direction="row" spacing={3}>*/}
                                {/*    <Grid item xs={7} md={7}>*/}
                                {/*        <Autocomplete*/}
                                {/*            multiple*/}
                                {/*            filterSelectedOptions*/}
                                {/*            id="tags-standard"*/}
                                {/*            options={portfolioList}*/}
                                {/*            getOptionLabel={(option) => option.name}*/}
                                {/*            style={{width: 300}}*/}
                                {/*            value={inputValue}*/}
                                {/*            onChange={onTagsChange}*/}
                                {/*            renderInput={(params) => (*/}
                                {/*                <TextField*/}
                                {/*                    {...params}*/}
                                {/*                    variant="outlined"*/}
                                {/*                    label="Current Portfolio list"*/}
                                {/*                    placeholder="Enter portfolio name"*/}
                                {/*                />*/}
                                {/*            )}*/}
                                {/*        />*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={2} md={2} className={classes.buttonsDiv}>*/}
                                {/*        <button*/}
                                {/*            className={classes.button}*/}
                                {/*            onClick={() => updatePortfolioList()}>*/}
                                {/*            Update List*/}
                                {/*        </button>*/}
                                {/*    </Grid>*/}
                                {/*</Grid>*/}

                                {/*        <u style={{textDecoration: "none"}}>*/}
                                {/*            <li>Basic advisory with execution</li>*/}
                                {/*            <li>Tax optimization advisory</li>*/}
                                {/*            <li>Equity investment program management advisory</li>*/}
                                {/*        </u>*/}
                            </Box>
                        </Grid>

                        <Grid item>
                            <Box border={1} borderColor="grey.500" borderRadius={16} className={classes.section}>
                                <Typography>Client Mode</Typography>
                                <Typography>Remove Client from your list</Typography>

                                {/*        <u style={{textDecoration: "none"}}>*/}
                                {/*            <li>Total pending fee: 0</li>*/}
                                {/*            <li>There are no invoices yet for you</li>*/}
                                {/*        </u>*/}
                            </Box>
                        </Grid>

                    </Grid>
                </div>
            </div>

            <Backdrop className={classes.backdrop} open={showLoading} onClick={() => {
            }}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    );
};
export default ClientAccount;
