import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

const TimeAreaGraph: React.FC<{ chartName: string; data: any }> = (props) => {
    const {chartName, data} = props;

    function calculateWindow(min, max) {
        let valArray = data[0].data
        let valArray2 = data[1].data
        let datap = {
            minData: 0,
            maxData: 0
        }
        let datap2 = {
            minData: 0,
            maxData: 0
        }

        if (min && max && valArray && valArray2 && valArray2.length > 0 && valArray.length > 0) {
            datap['minData']=valArray.reduce(function (prev, curr) {
                return (Math.abs(curr[0] - min) < Math.abs(prev[0] - min) ? curr : prev);
            });
            datap['maxData']=valArray.reduce(function (prev, curr) {
                return (Math.abs(curr[0] - max) < Math.abs(prev[0] - max) ? curr : prev);
            });
            let returnsVal = ((datap['maxData'][1] / 100) + 1) / ((datap['minData'][1] / 100) + 1)
            let daysDiff = (365 / moment(datap['maxData'][0]).diff(moment(datap['minData'][0]), "days"))
            let fin = ((Math.pow(returnsVal, daysDiff) - 1) * 100)
            datap2['minData']=valArray2.reduce(function (prev, curr) {
                return (Math.abs(curr[0] - min) < Math.abs(prev[0] - min) ? curr : prev);
            });
            datap2['maxData']=valArray2.reduce(function (prev, curr) {
                return (Math.abs(curr[0] - max) < Math.abs(prev[0] - max) ? curr : prev);
            });
            let returnsVal2 = ((datap2['maxData'][1] / 100) + 1) / ((datap2['minData'][1] / 100) + 1)
            let daysDiff2 = (365 / moment(datap2['maxData'][0]).diff(moment(datap2['minData'][0]), "days"))
            let fin2 = ((Math.pow(returnsVal2, daysDiff2) - 1) * 100)

            props.valueSetter(fin, fin2, moment(datap['minData'][0]).year(), moment(datap['maxData'][0]).year())
        }
    }

    const chartOptions = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            zoomType: "x",
        },
        colors:['#027ae6','#e65502'],
        title: {
            enabled: false,
            text: " ",
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            type: "datetime",
            events: {
                afterSetExtremes: function (event) {
                    let min = event.min
                    let max = event.max
                    calculateWindow(min, max);
                }
            },
        },
        yAxis: {
            title: {
                text: "Returns(%)",
            },
        },
        rangeSelector: {
            enabled: true,
            inputEnabled: false,
            buttons: [{
                type: 'year',
                count: 1,
                text: '1y'
            }, {
                type: 'year',
                count: 3,
                text: '3y'
            }, {
                type: 'year',
                count: 5,
                text: '5y'
            }, {
                type: 'year',
                count: 10,
                text: '10y'
            }, {
                type: 'all',
                text: 'All'
            }]
        },
        navigator: {
            enabled: true,
            series: {
                fillOpacity: 0.7,
            },
        },
        legend: {
            enabled: true,
        },
        tooltip: {
            pointFormat: "{series.name}:<b>{point.y:.1f}%</b>",
        },
        accessibility: {
            point: {
                valueSuffix: "%",
            },
        },
        plotOptions: {
            series: {
                compare: 'value',
            },
        },
        series: [
            {
                type: "line",
                name: "Portfolio",
                data: data[0].data,
                // lineColor: "#027ae6",

            },
            {
                type: "line",
                name: "Index",
                data: data[1].data,
                // lineColor: "#e65502",
                // fillColor: {
                //   linearGradient: {
                //     x1: 0,
                //     y1: 0,
                //     x2: 0,
                //     y2: 1,
                //   },
                //   stops: [
                //     [0, "#f2b8c6"],
                //     [1, "#ffffff"],
                //   ],
                // },
            },
        ],
    };

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} constructorType={'stockChart'}/>;
};

export default TimeAreaGraph;
