import React, {useContext} from "react";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
//import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import ClearIcon from "@material-ui/icons/Clear";
import AppWithoutSideBar from "./AppWithoutSideBar";
import {store} from "./SignIn";
import {checkCredenceClient, checkRole, logout, readFromLocal} from "./shared/utils";

const useStyles = makeStyles({
    list: {
        //width: 230,
        border: "0px",
    },
    fullList: {
        width: "auto",
        height: "100%",
    },
    move: {
        transform: "translateX(230px) rotate(-8deg)",
        transition: "all 300ms 0s ease-out",
        borderRadius: "20px",
        //height: "600px !important",
        overflowY: "hidden",
    },
    normal: {
        //height:"",
        //width:"500px",
        //border:"1px solid black",
        //overflowY: "scroll",
        scrollBehavior: "smooth",
        //height: "100vh",
        backgroundColor: "#fafafa",
        transformOrigin: "50% 0%",
        overflowX: "hidden",
    },
    paper: {
        backgroundColor: "#0b54f6",
        border: "0px",
        paddingTop: "70px",
        color: "#fff",
        fontWeight: 300,
        paddingLeft: "31px",
        width: 230,
        overflowX: "hidden",
    },
    selected: {
        fontWeight: 900,
        color: "#fff",
    },
    mark: {
        position: "absolute",
        width: "10px",
        height: "10px",
        borderRadius: "100%",
        backgroundColor: "#fff",
        left: "-10px",
    },
    imageDiv: {
        display: "flex",
    },
    name: {
        fontSize: "16px",
        fontWeight: 600,
        paddingLeft: 10,
    },
    role: {
        fontSize: "12px",
        paddingLeft: 10,
    },
    button: {
        color: "#0b54f6",
        backgroundColor: "#fff",
        borderRadius: "10px",
        padding: "12px 22px",
        textDecoration: "none",
        fontSize: "14px",
        fontWeight: 600,
    },
    inverseButton: {
        border: "2px solid #fff",
        borderRadius: "10px",
        padding: "12px 22px",
        color: "#fff",
        textDecoration: "none",
        fontSize: "14px",
        fontWeight: 600,
    },
    buttonsDiv: {
        width: "200px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "30px",
    },
    icon: {
        color: "#0b54f6",
    },
    iconWrapper: {
        backgroundColor: "#fff",
        width: "50px",
        height: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "100%",
    },
    exit: {
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
    },
});

const pathDict = {
    "Invest": "/invest",
    "My Funds": "/myFunds",
    "Advisory Funds": "/advisoryFund",
    "Clients": "/clients",
    "Team Settings": "/team-details",
    "My Dashboard": "/dashboard",
    "Account": "/account",
    "Cash Calls": "/cash-call",
    "Tax Saver": "/dashboard/tax-saver"
}

export default function AppWithSideBar() {
    const classes = useStyles();
    const [state, setState] = React.useState(false);
    const [route, setRoute] = React.useState("Home");
    const signinStore = useContext(store);
    const {signedIn, username} = signinStore.state;
    const isAdvisory = checkRole("Advisor")
    const isAdmin = checkRole("Admin")
    const isClient = checkRole("Client")
    const isCredenceClient = checkCredenceClient()

    const toggleDrawer = (open: boolean) => () =>
        // event: React.KeyboardEvent | React.MouseEvent
    {
        //if (
        //  event.type === "keydown" &&
        //  ((event as React.KeyboardEvent).key === "Tab" ||
        //    (event as React.KeyboardEvent).key === "Shift")
        //) {
        //  return;
        //}

        setState(open);
    };

    function getMobileMenuList() {
        if(isCredenceClient){
            return [""];
        }
        if (isAdvisory)
            return ["Advisory Funds", "Clients", "Invest", "My Dashboard",
                "Account",
                // "Risk Management",
                "Cash Calls",
                // "Additional Purchase",
            ];
        if (isAdmin)
            return [
                "Advisory Funds",
                "Clients",
                "Invest",
                "My Dashboard",
                "Account",
                "Team Settings",
                "Cash Calls",
                // "Additional Purchase",
            ];
        if (isClient)
            return [
                "Invest",
                "My Funds",
                "My Dashboard",
                "Account",
            ];
        return [
            "Invest",
            "My Dashboard",
            "Account",
            // "Risk Management",
            "Cash Calls",
            // "Additional Purchase",
        ];
    }

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            role="presentation"
        >
            <List>
                {getMobileMenuList().map((text, index) => (
                    <ListItem
                        button
                        key={text}
                        onClick={() => {
                            // setRoute(text);
                            window.open(pathDict[text], "_self")
                        }}
                    >
                        <span className={text === route ? classes.mark : ""}/>
                        <ListItemText
                            primary={text}
                            className={text === route ? classes.selected : ""}
                        />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <React.Fragment>
            {/*
      <CssBaseline />
		  */}
            <div
                id="base"
                style={{
                    // backgroundColor: "#222222",
                    backgroundColor: "#0b54f6",
                    height: "100vh",
                    width: "100vw",
                    overflowX: "hidden",
                }}
                onClick={(event: any) => {
                    if (event.target.id !== "mobilemenu") {
                        toggleDrawer(false)();
                    } else if (state && event.target.id === "mobilemenu") {
                        toggleDrawer(false)();
                    }
                }}
            >
                <div className={clsx(classes.normal, {[classes.move]: state})}>
                    {/* Here our main app goes
          <div></div>
			  */}
                    <AppWithoutSideBar sideBarOpen={state} toggleDrawer={toggleDrawer}/>
                </div>
                <Drawer
                    variant="persistent"
                    anchor={"left"}
                    open={state}
                    onClose={toggleDrawer(false)}
                    transitionDuration={300}
                    classes={{paper: classes.paper}}
                >
                    <div className={classes.imageDiv}>
                        <Avatar alt={username} src="#"/>
                        <span>
              <div className={classes.name}>{username}</div>
              <div className={classes.role}>
                {isCredenceClient ? 'Credence Client' : 'Investor'}
              </div>
            </span>
                    </div>
                    {list("left")}
                    <div className={classes.buttonsDiv}>
                        {signedIn ?
                            <a onClick={logout} className={classes.inverseButton}>
                                Logout
                            </a>
                            : <>
                                <a href="/login" className={classes.inverseButton}>
                                    Login
                                </a>
                                <a href="/register" className={classes.button}>
                                    Sign Up
                                </a>
                            </>}
                    </div>
                    <div className={classes.exit} onClick={toggleDrawer(false)}>
            <span className={classes.iconWrapper}>
              <ClearIcon className={classes.icon}/>
            </span>
                    </div>
                </Drawer>
            </div>
        </React.Fragment>
    );
}
