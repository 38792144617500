export const blogList = [
	{
		id: 1,
		title: "Important Factors to Consider Before Investing in Direct Equity ",
		subtitle: {
			title: [
				{
					title1: "Investing in equity",
					description1:
						"Although it's incredibly easy to trade online, if you're a serious investor, you need to know what you're getting into. When you consider the stock market, equity refers to the ownership of shares in a specific business or organisation. You automatically become a shareholder of a corporation if you purchase its stock. When you make a direct investment in a company, you can easily profit from its profits and receive a portion of them in the form of dividend payments. When you decide to sell your shares for a profit, you might also profit from the stock price rising. Aside from the financial rewards, you should be familiar with some features of direct investment before beginning your investment journey. ",
				},
				{
					title2: "Should you choose a direct stock investment?",
					description2:
						"Whether you are the correct kind of investor to make direct equity investments through the stock market should be your top priority. Everyone believes they are the best investors, but take a moment to consider whether this is really the case. Direct stock investments are a fantastic choice for risk-tolerant individuals. You can give direct equity a shot if youre prepared to take chances and you've taken chances successfully in the past. The stock market and direct equity may not be ideal for you if you have a low risk tolerance. ",
				},
				{
					title3: "Keep your goals in mind",
					description3:
						"What are your long-term aims and objectives? You must be willing to stick with it for the long run if you want to invest in direct equity. The majority of the time, stock markets are erratic, and seasoned investors are aware that if you are looking at the short term, there may be fluctuations. Experienced investors are aware that you must stick with your investment through difficult times because markets have a history of rising over the long term. As a result, you cannot be an irrational investor who sells a stock as soon as it begins to trend downward. Direct equities provide you with excellent returns commensurate with the substantial risk involved, but only if your investment goals are long-term focused.",
				},
			],
		},
		Disclaimer:
			"The information provided is a statement of opinion and not a statement of fact. It is not a guarantee of any particular outcome and you may therefore not use any information, in full or in part as the sole basis for any decision you take. It should not be construed as recommendation for investing in or not investing in any instruments issued by a company. An independent analysis and due diligence should be done before making any decision to invest/lend or not lend to the specific company. Opinion, projections and estimates are subject to change without notice.",

		description: `Simply put, when you think of shares of a particular corporation, you are thinking about ownership. Equity is essentially the total sum of money an investor receives once a company has paid off all of its debts (if any) and sold off all of its assets. Anytime an investor purchases stock in a firm, they automatically get a stake in the company. As a result, you are referred to as an equity shareholder in the business. Due to the fact that you own the company's stock (shares), you are qualified to partake in its profits. Investing in company shares is currently permitted and ${
			(
				<link to='https://makemyfund.in/'> </link>
			)
		} on the Indian stock market. You should be aware, though, that if you decide to invest in any company's equity, your potential returns will only materialise if share prices rise .`,

		authorAvatar:
			"https://images.moneycontrol.com/static-mcnews/2022/09/Savings-accounts-2-3.png?impolicy=website&width=770&height=431",
		slug: "important-factors-to-consider-before-investing-in-direct-equity",
	},
	{
		id: 2,
		title: "Online & Offline Mutual Fund Direct Plan Investing Guide ",

		description:
			"MakeMyFund is the first personalized equity investing platform in India where you can construct a stock portfolio that is optimum Self-directed investors' initial preference is mutual fund direct programmes. The explanation is straightforward: Direct mutual fund investments do not require a distributor.As a result, it not only reduces transaction expenses, if any, but also lowers fees for mutual fund direct plans. The cost difference may appear insignificant at first, but over time, the cost difference adds up to lakhs of rupees in value terms.",
		authorAvatar:
			"https://www.outlookindia.com/outlookmoney/public/uploads/article/gallery/2e2eb7beb6e92f9543917f5fb9a3e7c0.jpg",
		slug: "investing-in-equity",

		subtitle: {
			title: [
				{
					title1:
						"Through the website of the mutual fund house, invest in direct plans(Online)",
					description1:
						"Once you have chosen which fund to invest in, you can invest directly in mutual fund schemes using the Asset Management Company's online platform.If your KYC is finished, you can proceed with the stages listed below. ",
				},
				{
					title2: "Invest in direct mutual funds offline",
					description2:
						"By going to the AMC branch office, you can invest in direct funds. By providing the self-attested identification and address verification, you must complete KYC.After choosing the appropriate mutual fund plan, think about completing the normal application form or SIP form depending on the investing manner. To invest in direct plans with the AMC, you must select Direct.The direct plan option is appropriate for investors who have the knowledge to select the best fund and manage it independently. ",
				},
			],
		},
	},
];
