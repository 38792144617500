import moment from 'moment';
import axios from '../axios';
import { Factor } from '../types/factorTypes';
import { SERVER_BASE_ADDRESS } from './const';
import instance from "../axios";
import { RegisterFormValues } from '../Interfaces/RegistrationFormInterfaces';

export const clientMode = {
    'INT': "Introduction Only",
    'ADV': "Advisory",
    'MIX': "Mixed"
}
export const simulationParams = {
    'beta': '/simulation/smart-beta',
    'themes': '/simulation/theme-sectors',
    'themes2': '/simulation/sub-sector-inclusion'
}

export const unsecuredPages = ["", "login", "register", "publicFunds", "1", "2", "3", "admin", "highest_returns", "pvefresult", "ethics", "sectors", "themes", "car_purchase", "foreign_travel", "theme_sector_specific", "themeatic_pvef", "childrens_marriage", "house_purchase", "goals", "personal_inflation_rate", "children_education"]

export function cleanOutput(text, type = 0) {
    let check = moment(text, moment.ISO_8601, true)
    if (check.isValid())
        return moment(text).format("MMM Do YY");
    if (typeof text === "undefined")
        return "";
    if (typeof text === "string")
        return text.toString().replaceAll("_", " ");
    if (typeof text === "number") {
        if (type === 1)
            return parseFloat(text).toFixed(0);
        return parseFloat(text).toFixed(1) + "%";
    }
    // if (check.isValid())
}

export function loadScript(url: string) {
    document.body.appendChild(document.createElement("script")).src = url;
}

export const load_Script = (src: string) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
};

export function saveToLocal(key: string, value: any) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function saveAuth(token: string | undefined) {
    if (token !== undefined)
        saveToLocal('auth', token)
    else
        window.sessionStorage.removeItem("auth");
}

export function logout() {
    window.sessionStorage.clear();
    window.open("/", "_self");
}

export function removeFromLocal(key: string) {
    window.sessionStorage.removeItem(key);
}

export function isLoggedIn() {
    return window.sessionStorage.getItem('auth') !== undefined && window.sessionStorage.getItem('auth') !== null
}

export function getToken() {
    let authJson = window.sessionStorage.getItem('auth');
    if (authJson !== null && authJson !== undefined) {
        let auth = JSON.parse(authJson);
        return auth['token'];
    }
    return null;
}

export function getAuth() {
    let authJson = window.sessionStorage.getItem('auth');
    if (authJson !== null && authJson !== undefined)
        return JSON.parse(authJson);
    return undefined;
}

export function readFromLocal(key: string) {
    let json = window.sessionStorage.getItem(key);
    if (json === undefined || json === null)
        return undefined;
    return JSON.parse(json);
}

export function convertRelativeWeightsToRange(factorDict: any) {
    let weightSum = 0;
    for (let key in factorDict) {
        weightSum += parseFloat(factorDict[key]);
    }

    var normalizedWeights: Factor[] = [];

    for (let key in factorDict) {
        let normalizedFactor = parseFloat(factorDict[key]) / weightSum;
        normalizedWeights.push({ factor: key, value: normalizedFactor });
    }

    return normalizedWeights;
}

export async function postToBackend(url: string, data: any) {
    // const token = window.localStorage.getItem('token');

    // const headers = {
    //   'Content-Type': 'application/json',
    //   'Authorization': 'Token ' + token
    // }

    // return axios.post(SERVER_BASE_ADDRESS + url, data, {headers: headers})
    return axios.post(SERVER_BASE_ADDRESS + url, data)
        .then(result => result.data);
    // .catch(e => {if(e.response.status === 401){
    //   alert('Session expired. Please login again.');
    //   window.location.href='login';
    // }})

}

function checkClientToken(param: any, user: any) {
    let token = (param).match("[^?token=]*$")
    if (token != "")
        return axios.post("/account/accept-client", { "token": token[0], "user": user.id })
            .then((result: any) => {
                if (result.status === 200)
                    persistTeamAndRole(result.data);
            });
    else
        return Promise.resolve()
}

export function handlePreLogin(param: any, user: any, mode?: string) {
    checkClientToken(param, user);
    return getTeamAndRole()
}

function attachtoTeam(user: any, teamSlug: string) {
    if (teamSlug)
        return instance
            .post("/account/setTeamForUser", { "slug": teamSlug })
            .then((res) => {
                if (res.status === 200)
                    persistTeamAndRole(res.data);
            })
    return Promise.resolve()

}


export function handlePreRegister(param: any, user: any, teamSlug?: string) {
    return checkClientToken(param, user).then(() => {
        if (readFromLocal("originLink") && readFromLocal("originLink") != '')
            return attachtoTeam(user, readFromLocal("originLink"))
        else if (!teamSlug)
            return getTeamAndRole()
        if (!unsecuredPages.includes(teamSlug))
            return attachtoTeam(user, teamSlug)
    })
}

export function handleNextBuildPortfolio(
    mandate: string,
    portfolioConfig: any,
    setShowSimulateAnimation: any,
    simulationPath: string) {

    let universe_exclusions = readFromLocal("universe_exclusions");
    if (universe_exclusions !== undefined) {
        portfolioConfig.universe_selection.universe_exclusions = universe_exclusions;
    }
    saveToLocal('latestRouteMandate', mandate);
    saveToLocal('portfolioConfig-' + mandate, portfolioConfig);
    saveToLocal("lastCall", { data: portfolioConfig, link: simulationPath });

    setShowSimulateAnimation(true);

    axios.post(simulationPath, portfolioConfig)
        .then((res: any) => {
            //save the result to the local storage for the results page
            if (res.status === 200) {
                setShowSimulateAnimation(false);
                saveToLocal("simulationResult", res.data);
                saveToLocal('simulationResult-' + mandate, res.data);
                window.open("/pvefresult/" + mandate, "_self");
            } else {
                alert('Could not continue do to error.');

            }
        });
};

export function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function toTitleCase(str: string) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export function transact(gateway_name: string, auth_token: string, transaction_id: string) {
    const scGateway = new scDK({
        gateway: gateway_name,
        smallcaseAuthToken: auth_token,
        config: {
            amo: true
        }
    });
    scGateway.triggerTransaction({
        transactionId: transaction_id
    })
        .then((res: any) => {
            console.log(res);
            if (res.success === undefined || res.success === true) {
                alert("Trades successfully executed! You will be taken to dashboard.");
            } else {
                alert("Trade execution failed. This could be due to a problem with the broker's gateway. Please contact support. You will be taken to dashboard.");
            }

            window.open("/dashboard", "_self");
        })
        .catch((err: any) => {
            console.log(err);
            // alert ("Could not execute trades. Please contact support");
        })
}

export function formatToCurrency(amount: number | string) {
    if (typeof (amount) !== "number")
        amount = Number(amount);

    return amount.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
    });
    // if (k >= 10000000) {
    //   return (k / 10000000).toFixed(2) + " Cr";
    // } else if (k > 100000) {
    //   return (k / 100000).toFixed(2) + " Lac";
    // }
    // return k.toFixed(0);
}

export function isEmptyString(s: string) {
    return !s.replace(/\s/g, '').length
}

function persistTeamAndRole(data: any) {
    if (data.teamData)
        saveToLocal("teamData", data.teamData);
    if (data.roleData) {
        saveToLocal("role", data.roleData.role)
        // if (data.roleData.role === "Advisor")
        //     saveToLocal("isAdvisor", true);
        // if (data.roleData.role === "Client")
        //     saveToLocal("isClient", true);
    }
}

export function checkRole(role: String) {
    return readFromLocal("role") && readFromLocal("role")[0] === role
}

export function getTeamAndRole() {
    return instance
        .get("/account/getTeamSecured")
        .then((res) => {
            persistTeamAndRole(res.data);
        })
        .catch((err) => {

        });
}

export function validateEmptyJson(state: any) {
    for (let key in state) {
        if (state.hasOwnProperty(key)) {
            if (state[key] === "") {
                return false
            }
        }
    }
    return true
}

export function checkStatusObject(status) {
    if (typeof status === "undefined")
        return false
    return status.ops
}

export function isNormalInteger(str: String) {
    str = str.trim();
    if (!str) {
        return false;
    }
    str = str.replace(/^0+/, "") || "0";
    var n = Math.floor(Number(str));
    if (n !== Infinity && String(n) === str && n >= 0)
        return n
    else
        return false
}

export const generateTemplateData = (values: any) => {

    let templateData = {};

    switch (values.option) {
        case "Individual_RIA":
            templateData = {
                "templates": {
                    "field_data": {
                        "field_text_data": {
                            "Full Name": values.name,
                            "Email": values.email,
                            "Location": values.partnership_address,
                            "REG_Number": values.arn_ria_number == "" ? "-" : values.arn_ria_number,
                            "REG_Date": values.date_of_registration == "" ? "-" : values.date_of_registration,
                            "Engaged_Business": values.engaged_business,
                            "Fee": String(values.advisory_services_fee)
                        },
                        "field_date_data": {
                            "Date": moment(new Date()).format("DD MMMM YYYY")
                        }
                    },
                    "actions": [
                        {
                            "recipient_name": values.name,
                            "recipient_email": values.email,
                            "action_id": "29513000000017026",
                            "signing_order": 1,
                            "role": "Customer",
                            "verify_recipient": false,
                            "private_notes": "",
                            "is_embedded": true
                        },
                        {
                            "recipient_name": "Anand Ved",
                            "recipient_email": "anand@asqi.in",
                            "action_id": "29513000000017031",
                            "signing_order": 2,
                            "role": "Admin",
                            "verify_recipient": false,
                            "private_notes": ""
                        },
                        {
                            "recipient_name": values.name,
                            "recipient_email": values.email,
                            "action_id": "29513000000038020",
                            "signing_order": 3,
                            "role": "Receiver",
                            "verify_recipient": false,
                            "private_notes": ""
                        }
                    ],
                    "notes": ""
                }
            }
            break;
        case "Non-Individual_RIA":
            templateData = {
                "templates": {
                    "field_data": {
                        "field_text_data": {
                            "Full Name": values.name,
                            "Email": values.email,
                            "Partnership Name": values.partnership_name,
                            "Location": values.partnership_address,
                            "REG_Number": values.arn_ria_number == "" ? "-" : values.arn_ria_number,
                            "REG_Date": values.date_of_registration == "" ? "-" : values.date_of_registration,
                            "Engaged_Business": values.engaged_business,
                            "Fee": String(values.advisory_services_fee)
                        },
                        "field_date_data": {
                            "Date": moment(new Date()).format("DD MMMM YYYY")
                        }
                    },
                    "actions": [
                        {
                            "recipient_name": values.name,
                            "recipient_email": values.email,
                            "action_id": "29513000000021096",
                            "signing_order": 1,
                            "role": "Customer",
                            "verify_recipient": false,
                            "private_notes": "",
                            "is_embedded": true
                        },
                        {
                            "recipient_name": "Anand Ved",
                            "recipient_email": "anand@asqi.in",
                            "action_id": "29513000000021098",
                            "signing_order": 2,
                            "role": "Admin",
                            "verify_recipient": false,
                            "private_notes": ""
                        },
                        {
                            "recipient_name": values.name,
                            "recipient_email": values.email,
                            "action_id": "29513000000038036",
                            "signing_order": 3,
                            "role": "Receiver",
                            "verify_recipient": false,
                            "private_notes": ""
                        }
                    ],
                    "notes": ""
                }
            }
            break;
        case "Individual_Non_RIA":
            templateData = {
                "templates": {
                    "field_data": {
                        "field_text_data": {
                            "Full Name": values.name,
                            "Email": values.email,
                            "Location": values.partnership_address,
                            "REG_Number": values.arn_ria_number == "" ? "-" : values.arn_ria_number,
                            "REG_Date": values.date_of_registration == "" ? "-" : values.date_of_registration,
                            "Engaged_Business": values.engaged_business,
                            "Fee": String(values.advisory_services_fee)
                        },
                        "field_date_data": {
                            "Date": moment(new Date()).format("DD MMMM YYYY")
                        }
                    },
                    "actions": [
                        {
                            "recipient_name": values.name,
                            "recipient_email": values.email,
                            "action_id": "29513000000021036",
                            "signing_order": 1,
                            "role": "Customer",
                            "verify_recipient": false,
                            "private_notes": "",
                            "is_embedded": true
                        },
                        {
                            "recipient_name": "Anand Ved",
                            "recipient_email": "anand@asqi.in",
                            "action_id": "29513000000021038",
                            "signing_order": 2,
                            "role": "Admin",
                            "verify_recipient": false,
                            "private_notes": ""
                        },
                        {
                            "recipient_name": values.name,
                            "recipient_email": values.email,
                            "action_id": "29513000000038028",
                            "signing_order": 3,
                            "role": "Receiver",
                            "verify_recipient": false,
                            "private_notes": ""
                        }
                    ],
                    "notes": ""
                }
            }
            break;
        case "Non-Individual_Non_RIA":
            templateData = {
                "templates": {
                    "field_data": {
                        "field_text_data": {
                            "Full Name": values.name,
                            "Email": values.email,
                            "Partnership Name": values.partnership_name,
                            "Location": values.partnership_address,
                            "REG_Number": values.arn_ria_number == "" ? "-" : values.arn_ria_number,
                            "REG_Date": values.date_of_registration == "" ? "-" : values.date_of_registration,
                            "Engaged_Business": values.engaged_business,
                            "Fee": String(values.advisory_services_fee)
                        },
                        "field_date_data": {
                            "Date": moment(new Date()).format("DD MMMM YYYY")
                        }
                    },
                    "actions": [
                        {
                            "recipient_name": values.name,
                            "recipient_email": values.email,
                            "action_id": "29513000000021162",
                            "signing_order": 1,
                            "role": "Customer",
                            "verify_recipient": false,
                            "private_notes": "",
                            "is_embedded": true
                        },
                        {
                            "recipient_name": "Anand Ved",
                            "recipient_email": "anand@asqi.in",
                            "action_id": "29513000000021164",
                            "signing_order": 2,
                            "role": "Admin",
                            "verify_recipient": false,
                            "private_notes": ""
                        },
                        {
                            "recipient_name": values.name,
                            "recipient_email": values.email,
                            "action_id": "29513000000038044",
                            "signing_order": 3,
                            "role": "Receiver",
                            "verify_recipient": false,
                            "private_notes": ""
                        }
                    ],
                    "notes": ""
                }
            }
            break;
        case "CustomPortfolioClient":
            templateData = {
                "templates": {
                    "field_data": {
                        "field_text_data": {
                            "CFullName": `${values.fname} ${values.lname}`,
                            "DFullName": `${values.user__first_name} ${values.user__last_name}`,
                            "DAddress": values.partnership_address,
                            "AdvisoryFee": String(values.advisory_fee)
                        },
                        "field_date_data": {
                            "Date": moment(new Date()).format("DD MMMM YYYY")
                        }
                    },
                    "actions": [
                        {
                            "recipient_name": `${values.fname} ${values.lname}`,
                            "recipient_email": values.email,
                            "action_id": "29513000000080040",
                            "signing_order": 1,
                            "role": "Customer",
                            "verify_recipient": false,
                            "private_notes": "",
                            "is_embedded": true
                        },
                        {
                            "recipient_name": "Anand Ved",
                            "recipient_email": "anand@asqi.in",
                            "action_id": "29513000000080042",
                            "signing_order": 2,
                            "role": "Admin",
                            "verify_recipient": false,
                            "private_notes": ""
                        },
                        {
                            "recipient_name": `${values.fname} ${values.lname}`,
                            "recipient_email": values.email,
                            "action_id": "29513000000080044",
                            "signing_order": 3,
                            "role": "Receiver",
                            "verify_recipient": false,
                            "private_notes": ""
                        }
                    ],
                    "notes": ""
                }
            }
            break;
        case "DirectClient":
            templateData = {
                "templates": {
                    "field_data": {
                        "field_text_data": {
                            "CustomerName": `${values.fname} ${values.lname}`,
                            "Address": values.address
                        },
                        "field_boolean_data": {},
                        "field_date_data": {
                            "Date": moment(new Date()).format("DD MMMM YYYY")
                        },
                        "field_radio_data": {}
                    },
                    "actions": [
                        {
                            "recipient_name": `${values.fname} ${values.lname}`,
                            "recipient_email": values.email,
                            "action_id": "29513000000157022",
                            "signing_order": 1,
                            "role": "Customer",
                            "verify_recipient": false,
                            "private_notes": "",
                            "is_embedded": true
                        },
                        {
                            "recipient_name": "Anand Ved",
                            "recipient_email": "anand@asqi.in",
                            "action_id": "29513000000157024",
                            "signing_order": 2,
                            "role": "Admin",
                            "verify_recipient": false,
                            "private_notes": ""
                        },
                        {
                            "recipient_name": `${values.fname} ${values.lname}`,
                            "recipient_email": values.email,
                            "action_id": "29513000000157026",
                            "signing_order": 3,
                            "role": "Receiver",
                            "verify_recipient": false,
                            "private_notes": ""
                        }
                    ],
                    "notes": ""
                }
            }
    }

    return templateData
}

export const getUID = () => localStorage.getItem("MMF_UID");


export function CSVToArray(strData, strDelimiter = ",") {
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = (strDelimiter || ",");

    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp(
        (
            // Delimiters.
            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

            // Quoted fields.
            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

            // Standard fields.
            "([^\"\\" + strDelimiter + "\\r\\n]*))"
        ),
        "gi"
    );


    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData = [[]];

    // Create an array to hold our individual pattern
    // matching groups.
    var arrMatches = null;


    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while (arrMatches = objPattern.exec(strData)) {

        // Get the delimiter that was found.
        var strMatchedDelimiter = arrMatches[1];

        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (
            strMatchedDelimiter.length &&
            strMatchedDelimiter !== strDelimiter
        ) {

            // Since we have reached a new row of data,
            // add an empty row to our data array.
            arrData.push([]);

        }

        var strMatchedValue;

        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[2]) {

            // We found a quoted value. When we capture
            // this value, unescape any double quotes.
            strMatchedValue = arrMatches[2].replace(
                new RegExp("\"\"", "g"),
                "\""
            );

        } else {

            // We found a non-quoted value.
            strMatchedValue = arrMatches[3];

        }


        // Now that we have our value string, let's add
        // it to the data array.
        arrData[arrData.length - 1].push(strMatchedValue);
    }

    // Return the parsed data.
    return (arrData);
}

export const tempFixURL = (url: string) => {
    let splitURl = url.split("?")
    let modifiedURL = splitURl[0].replace("s3.amazonaws.com", "s3.ap-south-1.amazonaws.com")
    return modifiedURL
}

export const checkCredenceClient = () => {
    let isCredenceClient = false;
    if (readFromLocal("auth") && readFromLocal("auth")['category']) {
        if (readFromLocal("auth")['category'] === 'Credence Client') {
            isCredenceClient = true
        }
    }

    return isCredenceClient
}

export const getRegistrationFormValues = (email: string, formValues: any, kycValues: any): RegisterFormValues => {
    const {
        id,
        first_name,
        last_name,
        mobile,
        address,
        pan,
        bank_IFSC,
        bank_account_number,
        bank_account_type,
        category,
        KYC,
        risk_profiler,
    } = formValues || {};

    const registerFormValues: RegisterFormValues = {
        cID: id ?? 0,
        fname: first_name ?? "",
        lname: last_name ?? "",
        mobile: mobile ?? "",
        email: email ?? "",
        address: address ?? "",
        pan: pan ?? "",
        bank_IFSC: bank_IFSC ?? "",
        bank_account: bank_account_number ?? "",
        bank_type: bank_account_type === "S" ? "Savings" : "Current",
        risk_profiler: risk_profiler ?? [],
        category: category ?? "AS",
        KYC: kycValues ?? [],
        KYC_Status: KYC ?? "PENDING"
    }
    return registerFormValues;
}

export const isNotNullOrEmptyArray = (arr: any[]) => {
    return arr && arr.length > 0;
}

export const getRiskProfilerQuestions = () => {
    const questionsData = {
        questions: [
            {
                name: "birthdate",
                type: "text",
                inputType: "date",
                title: "Your birthdate:",
                isRequired: true,
                autoComplete: "bdate"
            },
            {
                title: "Time horizon for investment",
                type: "radiogroup",
                name: "Q1",
                isRequired: true,
                colCount: 1,
                choices: [
                    { text: "< 1 year", value: 5 },
                    { text: "1 - 3 years", value: 3 },
                    { text: "> 3 years", value: 2 },
                ]
            },
            {
                title: "Income bracket",
                type: "radiogroup",
                name: "Q2",
                isRequired: true,
                colCount: 1,
                choices: [
                    { text: "< 5 lakhs", value: 2 },
                    { text: "5 - 15 lakhs", value: 3 },
                    { text: "16 - 35 lakhs", value: 4 },
                    { text: "> 35 lakhs", value: 5 },
                ]
            },
            {
                title: "Existing net worth",
                type: "radiogroup",
                name: "Q3",
                isRequired: true,
                colCount: 1,
                choices: [
                    { text: "< 50 lakhs", value: 2 },
                    { text: "50 - 100 lakhs", value: 3 },
                    { text: "> 100 lakhs", value: 4 },
                ]
            },
            {
                title: "Which of the following best describes your lifestyle?",
                type: "radiogroup",
                name: "Q4",
                isRequired: true,
                colCount: 1,
                choices: [
                    { value: 1, text: "Typically live well within my means – expenses are a small fraction of income" },
                    { value: 3, text: "I live comfortably, and upgrade lifestyle with increase in income" },
                    { value: 5, text: "Lifestyle is high – expenses often match my income; have faced a cash crunch once or twice" },
                ]
            },
            {
                title: "Which amongst the following depicts your current lifestyle?",
                type: "radiogroup",
                name: "Q5",
                isRequired: true,
                colCount: 1,
                choices: [
                    { value: 5, text: "Not married and no one dependent on your earnings and does not have any financial obligation or burden and can have both short and long term objectives for investment" },
                    { value: 3, text: "Married and have a stable income flow with less or no financial obligations and eager to make short term and long term investments" },
                    { value: 2, text: "Family is dependent on you and have financial obligations" },
                    { value: 4, text: "Preparing for retirement and have utmost financial stability and an appetite for higher return" },
                    { value: 1, text: "Retired and dependent on existing investment" },
                ]
            },
            {
                title: "How familiar are you in investing in securities and trading in security market?",
                type: "radiogroup",
                name: "Q6",
                isRequired: true,
                colCount: 1,
                choices: [
                    { value: 0, text: "Not at all familiar" },
                    { value: 1, text: "Seldom makes investment" },
                    { value: 3, text: "Fairly familiar" },
                    { value: 5, text: "Very familiar and conduct research before making any investment" },
                ]
            },
            {
                title: "Investment Objective?",
                type: "radiogroup",
                name: "Q7",
                isRequired: true,
                colCount: 1,
                choices: [
                    { value: 1, text: "Regular income" },
                    { value: 2, text: "Long term growth" },
                    { value: 4, text: "Hedging" },
                    { value: 5, text: "Short term trading" },
                ]
            },
            {
                title: "You have invested in shares that you expect will become 5-baggers in 2-3 years. These share prices drop 30% soon after you buy them. You:",
                type: "radiogroup",
                name: "Q8",
                isRequired: true,
                colCount: 1,
                choices: [
                    { value: 2, text: "Sell" },
                    { value: 4, text: "average your cost by buying more" },
                    { value: 3, text: "wait and watch" },
                    { value: 5, text: "ready to apply complex methodology (i.e. take positions in future and options) to leverage out any exposure in cash segment" },
                ]
            },
            {
                title: "I would assign following weights to the volatile and aggressive portfolio",
                type: "radiogroup",
                name: "Q10",
                isRequired: true,
                colCount: 1,
                choices: [
                    { value: 5, text: "More than 75%" },
                    { value: 3, text: "More than 50% but less than 75%" },
                    { value: 2, text: "More than 25% but less than 50%" },
                    { value: 1, text: "Not more than 10%" },
                ]
            },
            {
                title: "Which of the following explains you with respect to you as a risk taker?",
                type: "radiogroup",
                name: "Q11",
                isRequired: true,
                colCount: 1,
                choices: [
                    { value: 3, text: "Willing to take risk for higher returns" },
                    { value: 2, text: "Can take calculated risks" },
                    { value: 1, text: "Low risk taking capacity" },
                    { value: 0, text: "Extremely averse to risk" },
                ]
            },
            {
                title: "If you hear about a good stock 'tip' from a friend, what do you typically do?",
                type: "radiogroup",
                name: "Q12",
                isRequired: true,
                colCount: 1,
                choices: [
                    { value: 1, text: "Ignore the advice" },
                    { value: 3, text: "Research the stock before buying the stock" },
                    { value: 4, text: "Invest a small amount without doing research" },
                    { value: 5, text: "Borrow money to buy the stock" },
                ]
            },
            {
                title: "You win Rs. 10 lakh in a lucky draw and you have a chance to increase your winnings. Which one of the following would you choose?",
                type: "radiogroup",
                name: "Q13",
                isRequired: true,
                colCount: 1,
                choices: [
                    { value: 1, text: "Take home the Rs. 10 lakh" },
                    { value: 2, text: "Take a 50% chance of winning Rs. 20 lakh" },
                    { value: 4, text: "Take a 20% chance of winning Rs. 50 lakh" },
                    { value: 5, text: "Take a 5% chance of winning Rs. 2 crore" },
                ]
            },
        ]
    };
    return questionsData;
}