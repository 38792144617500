
import  { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { blogList } from "../data";
import BlogItem from "../components/Blog/BlogItem";

const useStyles = makeStyles((theme: Theme) => ({
	mainView: {
		borderRadius: "24px",
		backgroundColor: "#fff",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		[theme.breakpoints.up("md")]: {
			padding: "65px 70px",
		},
	},
	header: {
		fontFamily: "Montserrat",
	},
	blogContainer: {
		paddingTop: theme.spacing(3),
	},
	artical: {
		paddingBottom: theme.spacing(3),
		textAlign: "center",
		fontSize: 30,
		fontWeight: 700,
	},
	card: {
		maxWidth: "70%",
		marginTop: 100,
	},
	media: {
		height: 120,
	},
}));
const BlogPost = () => {
	const classes = useStyles();
	const [blogs, setBlogs] = useState(blogList);
	return (
		<div style={{ justifyContent: "center" }}>
			<h2 style={{ textAlign: "center", color: "royalblue", fontSize: "25px" }}>
				BLOG LIST
			</h2>
			<BlogItem blogs={blogs} />
			<title>
				Factors to Consider When Investing in Equity Share - MakeMyFund
			</title>
			<meta
				name='description'
				content='You need to be very careful while investing in various types of mutual funds. Here is a list of some important things one may consider before investing in Direct Equity Funds.	'
			/>
		</div>
	);
};
export default BlogPost;
