import { useContext } from 'react';

import { Container, Snackbar } from '@material-ui/core'
import { AlertContext } from '../../AlertProvider';
import { Alert, AlertTitle } from '@material-ui/lab';


function AlertMessage() {

    const { closeAlert, alertState } = useContext(AlertContext);

    return (
        <Container>
            <Snackbar open={alertState.open} autoHideDuration={5000} onClose={closeAlert} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
                <Alert onClose={closeAlert} severity={alertState.responseStatus}>
                    {alertState.title && (<AlertTitle>{alertState.title}</AlertTitle>)}
                    {alertState.description}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default AlertMessage