import { createContext, useReducer } from "react";


export interface alertSchema {
    open?: boolean,
    title?: string | undefined,
    description: string,
    responseStatus: 'error' | 'warning' | 'info' | 'success'
}

const initialState: alertSchema = {
    open: false,
    description: '',
    responseStatus: 'success'
}

const actions = {
    OPEN: "OPEN",
    CLOSE: "CLOSE",
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case actions.OPEN:
            return {
                ...state,
                open: true,
                title: action.data.title,
                description: action.data.description,
                responseStatus: action.data.responseStatus
            };
        case actions.CLOSE: {
            return {
                ...state,
                open: false,
            };
        }
        default:
            return state;
    }
};

export const AlertContext = createContext();

const AlertProvider = ({ children }: { children: any }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        alertState: state,
        openAlert: (data: alertSchema) => {
            dispatch({ type: actions.OPEN, data });
        },
        closeAlert: () => {
            dispatch({ type: actions.CLOSE });
        }
    };

    return (
        <AlertContext.Provider value={value}>
            {children}
        </AlertContext.Provider>
    );
};

export default AlertProvider;